import './styles.css';
import { FaCheck } from 'react-icons/fa';

interface CheckboxProps {
  name?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ name, checked, onChange }: CheckboxProps) => (
  <>
    <input type="checkbox" name={name} checked={checked} onChange={onChange} />
    <span className={`checkbox${checked ? '--checked' : ''}`}>
      {checked && <FaCheck />}
    </span>
  </>
);

export default Checkbox;
