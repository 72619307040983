import './styles.css';
import { useContext } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useModalState } from 'hooks';
import GutterSizePicker from '../GutterSizePicker';
import { Loader, OptionalInput } from 'shared/components';
import CouplersAndGuttersModal from '../CouplersAndGuttersModal';
import {
  useMyContext,
  calcNumberOfRequiredPieces,
  findSuspension,
} from 'helpers';
import { ApiHashContext } from 'contexts/apiHashContext';
import { ProjectsContext } from 'contexts/projectsContext';

import type { Dimension, Step3ApiRes } from '../../types';
import { API_HOST } from 'shared/constants';

const sizesToObj = (sizes: Dimension[]) => {
  const [biggest, ...rest] = sizes.map((d) => d.dimensions);
  const sorted = [...rest, biggest];

  return sorted.map((s) => ({
    w: parseInt(s.split('x')[0].trim()),
    h: parseInt(s.split('x')[1].trim()),
  }));
};

const getGutterObj = (
  gutter: string | null,
  size: string | null,
  step3Data: Dimension[]
) => {
  if (!gutter || !size || !step3Data[0]) return null;

  return (
    step3Data
      .filter((d) => d.dimensions === size)[0]
      ?.availableTrays.find((t) => t.cr === gutter) || null
  );
};

const ElectroSkandiaKey = 'es';

const SizeForm = () => {
  const { apiHash } = useContext(ApiHashContext);
  const [isOpen, open, close] = useModalState({
    gutter: false,
    dataGutter: false,
  });
  const { currentConfiguration, setConfig, goBack, goForth } =
    useMyContext<3>();
  const {
    lid,
    size,
    shape,
    length,
    gutter,
    coupler,
    material,
    mountType,
    dataGutter,
    suspension,
  } = currentConfiguration.data;
  const { t } = useTranslation();
  const { isFetching, error, data } = useQuery(
    'step3Data',
    () =>
      axios
        .post<Step3ApiRes>(`${API_HOST}/wibe/step3/${apiHash}`, {
          selectedClassification: material.split(' ')[0],
          selectedSerie: shape,
        })
        .then((res) => {
          const sizes = sizesToObj(res.data.availableDimensions);
          if (!size || !sizes.find((s) => `${s.w} x ${s.h}` === size)) {
            const smallestSize = findSmallestSize(sizes);
            setConfig({ size: smallestSize }, false);
          }
          return res.data.availableDimensions;
        }),
    { refetchOnWindowFocus: false }
  );

  const step3Data = [...(data || [])];
  const gutterObj = getGutterObj(gutter, size, step3Data);
  const { isValidDistributor } = useContext(ProjectsContext);

  const findSmallestSize = (sizes: { w: number; h: number }[]) => {
    const sortedSizes = sizes.sort((a, b) => a.w - b.w || a.h - b.h);
    return `${sortedSizes[0].w} x ${sortedSizes[0].h}`;
  };

  const selectedSuspension = findSuspension(
    gutterObj?.suspensions || [],
    mountType,
    suspension
  );

  const selectedDataGutter = gutterObj?.dataChannels?.find(
    (d) => d.cr === dataGutter
  );
  const requiredPieces = calcNumberOfRequiredPieces(
    gutterObj?.length,
    currentConfiguration.data.length
  );

  const roundToDivisibleByN = (input: number, n: number) =>
    Math.ceil(input / n) * n;

  const calcAdditionalComponents = () => {
    const componentCount = (gutterObj?.additionalTrayComponents || []).reduce(
      (acc, curr) => acc + curr.autoAddQuantity,
      0
    );

    return componentCount * requiredPieces;
  };

  if (isFetching) return <Loader />;
  if (error)
    return <p className="center-x api-error">{t('sideform.error.message')}</p>;

  return (
    <div className="size-form">
      <h2>
        {t('main.step3.choose.input.size')}
        <p className="text-secondary auto-add-msg">
          {t('main.step3.choose.input.size.desc')}
        </p>
      </h2>

      {step3Data.length ? (
        <div>
          <div className="size-config">
            {size && (
              <GutterSizePicker
                sizes={sizesToObj(step3Data)}
                currentSize={size}
                onSelectSize={(size) => setConfig({ size }, false)}
                step3Data={step3Data}
              />
            )}

            {step3Data[0]?.availableTrays.length > 1 && (
              <label>
                <strong>{t('global.choose.gutter')}</strong>
                <input
                  type="text"
                  value={gutterObj?.crDescription}
                  onClick={() => open('gutter')}
                />
              </label>
            )}

            <label>
              <strong>{t('main.step3.gutter.length')}</strong>
              <div>
                <input
                  type="number"
                  value={currentConfiguration.data.length || ''}
                  onChange={(e) =>
                    setConfig({ length: parseInt(e.target.value) }, false)
                  }
                  onBlur={() =>
                    length &&
                    setConfig(
                      {
                        length: roundToDivisibleByN(
                          length,
                          parseInt(gutterObj?.length || '1')
                        ),
                      },
                      false
                    )
                  }
                />
                <span className="measurement-unit">
                  {t('global.unit.meter')}
                </span>
              </div>
              {calcAdditionalComponents() ? (
                <>
                  <span className="text-secondary">
                    {t('additional.items.msg.item.count').replace(
                      '#items',
                      calcAdditionalComponents().toString()
                    )}
                  </span>
                  <br />
                </>
              ) : null}
              <span className="text-secondary">
                {t('main.step3.num.of.pcs.required')} {requiredPieces} x{' '}
                {gutterObj?.length}
              </span>
            </label>

            <div className="optional-inputs">
              {gutterObj?.covers?.length && (
                <OptionalInput
                  name="lidLength"
                  toggleText={t('selected.coupler.bend.item.include.lid')}
                  label={t('main.step3.lid.length')}
                  type="number"
                  unitLength={gutterObj?.length}
                />
              )}
              {gutterObj?.coverJoint && lid && (
                <OptionalInput
                  name="coverJointLength"
                  toggleText={t('main.step3.include.coverJoint.optional')}
                  label={t('main.step3.include.joint.length')}
                  type="number"
                  unitLength={gutterObj?.coverJoint.length || '0'}
                />
              )}
              {gutterObj?.wallDividers?.length && (
                <OptionalInput
                  name="dividerLength"
                  toggleText={t('main.step3.include.separator')}
                  label={t('main.step3.separator.length')}
                  type="number"
                  unitLength={gutterObj?.wallDividers[0].length}
                  additionalOptions={gutterObj?.wallDividers}
                />
              )}
              {gutterObj?.dataChannels?.length && (
                <OptionalInput
                  toggleText={t('main.step3.include.data.gutter')}
                  isInitiallyOpen={!!dataGutter}
                  onToggleVisible={(visible) =>
                    visible
                      ? open('dataGutter')
                      : setConfig({ dataGutter: '' }, false)
                  }
                >
                  <strong>{t('main.step3.data.gutter')}</strong>
                  <input
                    type="text"
                    value={selectedDataGutter?.crDescription}
                    onClick={() => open('dataGutter')}
                  />
                </OptionalInput>
              )}
              {gutterObj?.suspensions?.length && (
                <OptionalInput
                  name="suspension"
                  toggleText={t('main.step3.suspension.included')}
                  label={t('global.choose.suspension')}
                  type="text"
                  unitLength={gutterObj?.length}
                  additionalOptions={gutterObj?.suspensions}
                  pendants={gutterObj?.pendants}
                  supportDistances={gutterObj?.supportDistances}
                />
              )}
            </div>
          </div>

          <div className="product-info-sections">
            <section className="product-info">
              <div>
                {shape && (
                  <label>
                    <span className="text-secondary">
                      {t('global.surface.treatment')}
                    </span>
                    <p>{shape}</p>
                  </label>
                )}
                {isValidDistributor(ElectroSkandiaKey) ? (
                  <label>
                    <span className="text-secondary">eNumber</span>
                    <p>{gutterObj?.enumber}</p>
                  </label>
                ) : (
                  <label>
                    <span className="text-secondary">
                      {t('global.tray.details.cr')}
                    </span>
                    <p>{gutterObj?.cr}</p>
                  </label>
                )}
                <label>
                  <span className="text-secondary">
                    {t('global.tray.details.gutter.name')}
                  </span>
                  <p>{gutterObj?.title}</p>
                </label>
                <label>
                  <span className="text-secondary">
                    {t('main.step3.tray.details.gutter.length')}
                  </span>
                  <p>{gutterObj?.length}</p>
                </label>
                {gutterObj?.thickness && (
                  <label>
                    <span className="text-secondary">
                      {t('main.step3.tray.details.gutter.thickness')}
                    </span>
                    <p>{gutterObj?.thickness}</p>
                  </label>
                )}
                <label>
                  <span className="text-secondary">
                    {t('global.tray.details.gutter.desc')}
                  </span>
                  <p>{gutterObj?.crDescription}</p>
                </label>
                <label>
                  <span className="text-secondary">
                    {t('global.surface.treatment')}
                  </span>
                  <p>{gutterObj?.surfaceTreatment}</p>
                </label>
              </div>
              <div>
                <img src={gutterObj?.imageUrl} alt="" />
              </div>
            </section>
            {selectedSuspension && (
              <section className="product-info">
                <div>
                  <label>
                    <span className="text-secondary">
                      {t('main.step3.suspension')}
                    </span>
                    <p>{selectedSuspension?.crDescription}</p>
                  </label>
                </div>
                <div>
                  <img src={selectedSuspension?.imageUrl} alt="" />
                </div>
              </section>
            )}
          </div>
        </div>
      ) : (
        <div className="center-x">
          {t('sizeform.no.product.match.error.message')}
        </div>
      )}

      <div className="form-footer">
        <button className="btn-secondary" onClick={goBack}>
          {t('global.previous')}
        </button>
        <button
          disabled={!length}
          onClick={() => {
            const defaultCoupler = gutterObj?.couplers?.find(
              (c) => c.isDefault
            );

            setConfig(
              defaultCoupler && !coupler
                ? { gutterObj, coupler: defaultCoupler.cr }
                : { gutterObj },
              false
            );
            goForth();
          }}
        >
          {t('global.next')}
        </button>
      </div>

      {isOpen.gutter && gutter && (
        <CouplersAndGuttersModal
          isOpen={isOpen.gutter}
          onClose={() => close('gutter')}
          step3Data={step3Data.filter((s) => s.dimensions === size)}
        />
      )}
      {isOpen.dataGutter && (
        <CouplersAndGuttersModal
          isOpen={isOpen.dataGutter}
          onClose={() => close('dataGutter')}
          step3Data={step3Data.filter((s) => s.dimensions === size)}
          isDataGutter
        />
      )}
    </div>
  );
};

export default SizeForm;
