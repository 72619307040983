import './styles.css';
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

import { useTranslation } from 'react-i18next';

import { AccessoryCard, Accordion, Loader, Modal } from 'shared/components';
import { useMyContext } from 'helpers';
import { ApiHashContext } from 'contexts/apiHashContext';

import type { ModalProps } from 'shared/types';
import type { Accessory, Step5ApiRes } from '../../types';
import { API_HOST } from 'shared/constants';

type AvailableAccessories = {
  grouping: string;
  accessories: (Accessory & { quantity: number })[];
}[];

interface AccessoriesModalProps extends ModalProps {
  advanceStep: boolean;
  tray?: string;
}

const AccessoriesModal = ({
  tray,
  isOpen,
  onClose,
  advanceStep,
}: AccessoriesModalProps) => {
  const { t } = useTranslation();
  const { apiHash } = useContext(ApiHashContext);
  const { currentProject, currentConfiguration, setConfig } = useMyContext<4>();
  const gutter = tray || currentConfiguration?.data.gutter;
  const accessories = useMemo(() => {
    return tray
      ? currentProject.configurations.find((c) => c.data.gutter === tray)?.data
          .accessories || []
      : currentConfiguration?.data.accessories;
  }, [
    currentConfiguration?.data.accessories,
    currentProject.configurations,
    tray,
  ]);
  const [availableAccessories, setAvailableAccessories] =
    useState<AvailableAccessories>([]);
  const { isLoading, error, data } = useQuery(
    'step5Data',
    () =>
      axios
        .post<Step5ApiRes>(`${API_HOST}/wibe/step5/${apiHash}`, {
          selectedTray: gutter,
        })
        .then((res) => res.data),
    { refetchOnWindowFocus: false }
  );

  const getStep5Data = useCallback(async () => {
    try {
      let availableAccessories = data!.availableAccessories.map((a) => ({
        ...a,
        accessories: a.accessories.map((a) => ({ ...a, quantity: 0 })),
      }));

      if (accessories.length) {
        availableAccessories = availableAccessories.map((a) => ({
          ...a,
          accessories: a.accessories.map((a) => {
            const foundAccessory = accessories.find(({ cr }) => cr === a.cr);
            return foundAccessory ? { ...a, ...foundAccessory } : a;
          }),
        }));
      }

      setAvailableAccessories(availableAccessories);
    } catch (err) {
      console.log(err);
    }
  }, [accessories, data]);

  const handleClick = () => {
    const accessories = availableAccessories
      .flatMap((a) => a.accessories)
      .filter((a) => a.quantity > 0);

    setConfig({ accessories }, advanceStep);
    onClose();
  };

  useEffect(() => {
    data && getStep5Data();
  }, [tray, data, getStep5Data]);

  return (
    <Modal isOpen={isOpen} className="accessories-modal">
      <Modal.Header onClose={onClose}>
        {t('accessories.modal.select.accesories')}
      </Modal.Header>
      <Modal.Content className="accessories-container">
        <>
          {isLoading && <Loader />}
          {error && (
            <p className="center-x api-error">
              {t('fetching.product.accessories.error.msg')}
            </p>
          )}
          {data?.availableAccessories.length ? (
            availableAccessories.map(({ grouping, accessories }, i) => (
              <Accordion
                key={grouping}
                title={grouping}
                borderBottom={i === availableAccessories.length - 1}
              >
                {accessories.map((a) => (
                  <AccessoryCard
                    key={a.cr}
                    {...a}
                    setQuantity={(accessory, quantity) => {
                      setAvailableAccessories((prev) =>
                        prev.map((a) => ({
                          ...a,
                          accessories: a.accessories.map((a) =>
                            a.cr === accessory
                              ? { ...a, quantity: quantity >= 0 ? quantity : 0 }
                              : a
                          ),
                        }))
                      );
                    }}
                  />
                ))}
              </Accordion>
            ))
          ) : (
            <p className="center-x">{t('no.accessories.found.error.msg')}</p>
          )}
        </>
      </Modal.Content>
      <Modal.Footer>
        <button onClick={onClose} className="btn-tertiary">
          {t('global.close')}
        </button>
        <button onClick={handleClick}>{t('global.next')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccessoriesModal;
