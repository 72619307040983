import { Suspension } from 'components/MultiStepForm/types';

const findSuspension = (
  suspensions: Suspension[],
  mountType: string | null,
  suspension: string | null
) => {
  const suspensionObj = suspensions.find(
    (b) => b.mountType === mountType && b.cr === suspension
  );

  return suspensionObj;
};

export default findSuspension;
