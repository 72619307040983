import './styles.css';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'shared/types';
import { SingleInputModal } from 'shared/components';
import { useMyContext } from 'helpers';

type NewConfigurationsModalProps = ModalProps & { project: string } & (
    | {
        action: 'create';
        id?: never;
        configName?: never;
        subProjectId?: never;
        subProjectType?: never;
        closeProjectsModal?:  () => void;
      }
    | {
        action: 'edit';
        id: string;
        configName: string;
        subProjectId: string;
        subProjectType: 'pendants' | 'subProjects' | undefined;
        closeProjectsModal?:  () => void;
      }
  );

const UpsertConfigurationModal = ({
  action,
  isOpen,
  onClose,
  project,
  subProjectId,
  configName,
  subProjectType,
  id,
  closeProjectsModal
}: NewConfigurationsModalProps) => {
  const { t } = useTranslation();
  const { createProjectItem, editProjectItem } = useMyContext();
  const [configurationName, setConfigurationName] = useState(
    action === 'edit' ? configName || '' : ''
  );

  const configNameCallback = useCallback(() => {
    if (isOpen && action === 'edit') {
      setConfigurationName(configName || '');
    }
  }, [action, configName, isOpen]);

  useEffect(() => {
    configNameCallback();
  }, [configNameCallback]);

  return (
    <SingleInputModal
      isOpen={isOpen}
      onClose={onClose}
      title={`${
        action === 'edit'
          ? t('project.modal.edit.configuration')
          : t('project.review.add.configuration')
      } `}
      inputConfig={{
        label: t('project.configuration.name'),
        placeholder: t('placeholder.my.configuration'),
        value: configurationName,
        onChange: (e) => setConfigurationName(e.target.value),
      }}
      actions={{
        dismiss: {
          label: t('global.close') || 'Close',
          onClick: () => {
            setConfigurationName('');
            onClose();
          },
        },
        confirm: {
          onClick: () => {
            action === 'edit'
              ? editProjectItem(
                  'configurations',
                  project,
                  id,
                  configurationName,
                  subProjectId,
                  subProjectType && [subProjectType]
                )
              : createProjectItem('configurations', project, configurationName);
            setConfigurationName('');
            onClose();
            if(closeProjectsModal){
              closeProjectsModal();
            }
          },
        },
      }}
    />
  );
};

export default UpsertConfigurationModal;
