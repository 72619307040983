import './styles.css';
import { useState, useContext } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

import { useTranslation } from 'react-i18next';

import { AttachmentCard, Modal, Loader } from 'shared/components';
import { useMyContext } from 'helpers';
import { ApiHashContext } from 'contexts/apiHashContext';
import { useModalState } from 'hooks';
import AccessoriesModal from '../AccessoriesModal';

import type { Bend, Step4ApiRes } from '../../types';
import { API_HOST } from 'shared/constants';

type AvailableBends = (Bend & {
  quantity: number;
  coverQuantity: number;
})[];

const AttachmentForm = () => {
  const [isOpen, open, close] = useModalState({
    noBends: false,
    continue: false,
    accessories: false,
  });
  const { t } = useTranslation();
  const [availableBends, setAvailableBends] = useState<AvailableBends>([]);
  const { apiHash } = useContext(ApiHashContext);
  const { goBack, goForth, currentConfiguration, setConfig } =
    useMyContext<4>();
  const { gutter, bends, gutterObj } = currentConfiguration?.data;
  const hasCouplers = !!gutterObj.couplers;
  const { isLoading, error } = useQuery(
    'step4Data',
    () =>
      axios
        .post<Step4ApiRes>(`${API_HOST}/wibe/step4/${apiHash}`, {
          selectedTray: gutter,
        })
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.availableBends.length < 0) {
          open('noBends');
          return;
        }

        let availableBends = data.availableBends.flatMap(({ bends }) =>
          bends.map((b) => ({
            ...b,
            quantity: 0,
            coverQuantity: 0,
          }))
        );

        if (bends.length) {
          availableBends = availableBends.map((b) => {
            const foundBend = bends.find(
              ({ cr, name }) => cr === b.cr && name === b.name
            );

            return foundBend ? { ...b, ...foundBend } : b;
          });
        }

        !availableBends.length && open('noBends');
        setAvailableBends(availableBends);
      },
    }
  );

  if (isLoading) return <Loader />;
  if (error)
    return (
      <p className="center-x api-error">
        {t('fetching.product.attachments.error.msg')}
      </p>
    );

  return (
    <div className="attachment-form">
      <h2>{t('main.step4.choose.attachment')}</h2>
      <div
        className="attachments-container"
        style={{ height: !availableBends.length ? '100%' : '' }}
      >
        {availableBends.length ? (
          availableBends.map((bend) => (
            <AttachmentCard
              key={`option-${bend.cr}-${bend.name}`}
              hasCouplers={hasCouplers}
              setBendCount={(bendOrCover, id, name, count) =>
                setAvailableBends((bends) =>
                  bends.map((b) =>
                    b.cr === id && b.name === name
                      ? {
                          ...b,
                          [bendOrCover === 'bend'
                            ? 'quantity'
                            : 'coverQuantity']: count >= 0 ? count : 0,
                        }
                      : b
                  )
                )
              }
              {...bend}
            />
          ))
        ) : (
          <Modal isOpen={isOpen.noBends}>
            <Modal.Header onClose={() => close('noBends')} />
            <Modal.Content className="center-x">
              <h3>{t('bend.modal.step4.no.attachments.available.msg')}</h3>
            </Modal.Content>
            <Modal.Footer style={{ flexDirection: 'row-reverse' }}>
              <button onClick={() => close('noBends')} className="btn-tertiary">
                {t('global.close')}
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <Modal
        isOpen={isOpen.continue}
        className="accessories-confirmation-modal"
      >
        <Modal.Header onClose={() => close('continue')}>
          {t('main.content.accessories')}
        </Modal.Header>
        <Modal.Content className="center-x">
          <p>{t('accessories.modal.confirmation.msg1')}</p>
          <p>{t('accessories.modal.confirmation.msg2')}</p>
          <p>{t('accessories.modal.confirmation.msg3')}</p>
          <p>{t('accessories.modal.confirmation.msg4')}</p>
          <div>
            <button className="btn-secondary" onClick={goForth}>
              {t('accessories.modal.no')}
            </button>
            <button
              onClick={() => {
                close('continue');
                open('accessories');
              }}
            >
              {t('accessories.modal.yes')}
            </button>
          </div>
        </Modal.Content>
      </Modal>

      <AccessoriesModal
        isOpen={isOpen.accessories}
        onClose={() => close('accessories')}
        advanceStep
      />

      <div className="form-footer">
        <button onClick={goBack} className="btn-secondary">
          {t('global.previous')}
        </button>
        <button
          onClick={() => {
            setConfig(
              { bends: availableBends.filter((b) => b.quantity > 0) },
              false
            );
            open('continue');
          }}
          disabled={!currentConfiguration?.data.material}
        >
          {t('global.next')}
        </button>
      </div>
    </div>
  );
};

export default AttachmentForm;
