import './styles.css';
import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Modal, ProductThumbnail } from 'shared/components';
import { useMyContext } from 'helpers';
import ReactGA from 'react-ga4';
import type { ModalProps } from 'shared/types';
import type { Dimension } from '../../types';

type Item = { name: string; imgUrl: string; cr: string };

type CouplersOrGutterModalProps = ModalProps & {
  step3Data: Dimension[];
  isDataGutter?: boolean;
};

const CouplersOrGutterModal = ({
  isOpen,
  onClose,
  step3Data,
  isDataGutter,
}: CouplersOrGutterModalProps) => {
  const { t } = useTranslation();
  const { currentConfiguration, setConfig } = useMyContext<3>();
  const { gutter, coupler, dataGutter } = currentConfiguration.data;
  const [items, setItems] = useState<Item[]>([]);
  const [type, setType] = useState<'couplers' | 'gutters' | 'dataGutter'>(
    isDataGutter ? 'dataGutter' : 'couplers'
  );
  const [selectedGutter, setSelectedGutter] = useState(
    dataGutter || gutter || ''
  );

  const getCouplersOrGutters = useCallback(() => {
    if (isDataGutter) {
      setItems(
        (step3Data[0].availableTrays[0].dataChannels || []).map((g) => ({
          name: g.crDescription,
          imgUrl: g.imageUrl,
          cr: g.cr,
        }))
      );
    } else {
      const type =
        step3Data[0].availableTrays.length > 1 ? 'gutters' : 'couplers';
      setType(type);

      setItems(
        (type === 'couplers'
          ? step3Data[0].availableTrays[0].couplers
          : step3Data[0].availableTrays
        ).map((c) => ({
          name: c.crDescription,
          imgUrl: c.imageUrl,
          cr: c.cr,
        }))
      );

      type === 'couplers' &&
        setSelectedGutter(step3Data[0].availableTrays[0].cr);

      type === 'couplers' &&
        ReactGA.event({
          category: 'CableTracker.couplers',
          action: 'select_coupler',
          label: step3Data[0].availableTrays[0].cr,
        });
    }
  }, [isDataGutter, step3Data]);

  useEffect(() => {
    getCouplersOrGutters();
  }, [getCouplersOrGutters, isOpen]);

  const setGutterCallback = useCallback(() => {
    gutter &&
      type === 'gutters' &&
      gutter !== selectedGutter &&
      setSelectedGutter(gutter);

    ReactGA.event({
      category: 'CableTracker.gutters',
      action: 'select_gutter',
      label: gutter || '',
    });
  }, [gutter, selectedGutter, type]);

  useEffect(() => {
    setGutterCallback();
  }, [setGutterCallback]);

  return (
    <Modal isOpen={isOpen} className="couplers-modal">
      <Modal.Header onClose={onClose} withoutCloseButton={type === 'couplers'}>
        {type !== 'couplers'
          ? t('global.choose.gutter')
          : t('coupler.modal.choose.type')}
      </Modal.Header>
      <Modal.Content
        style={{ flexWrap: type !== 'couplers' ? 'nowrap' : 'wrap' }}
      >
        {items.map(({ name, imgUrl, cr }) => (
          <div
            key={`option-${name}-${cr}`}
            className="coupler"
            onClick={() => {
              if (type !== 'couplers') {
                setSelectedGutter(cr);
              } else {
                setConfig({ coupler: cr, gutter: selectedGutter }, false);
                ReactGA.event({
                  category: 'CableTracker.couplers',
                  action: 'select_gutter',
                  label: 'coupler_' + cr + '__gutter' + selectedGutter,
                });
                onClose();
              }
            }}
          >
            <p>{name}</p>
            <ProductThumbnail
              img={imgUrl}
              selected={
                type === 'couplers' ? coupler === cr : selectedGutter === cr
              }
            />
          </div>
        ))}
      </Modal.Content>
      {type !== 'couplers' && (
        <Modal.Footer>
          <button className="btn-tertiary" onClick={onClose}>
            {t('global.cancel')}
          </button>
          <button
            onClick={
              selectedGutter
                ? () => {
                    ReactGA.event({
                      category: 'CableTracker.gutters',
                      action: 'select_gutter',
                      label: isDataGutter ? 'coupler_' + coupler : '',
                    });
                    setConfig(
                      {
                        [isDataGutter ? 'dataGutter' : 'gutter']:
                          selectedGutter,
                        coupler: isDataGutter ? coupler : null,
                      },
                      false
                    );
                    onClose();
                  }
                : undefined
            }
          >
            {t('global.next')}
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CouplersOrGutterModal;
