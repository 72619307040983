import './styles.css';
import { Configuration, ModalProps } from 'shared/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Modal } from 'shared/components';
import ReactGA from 'react-ga4';
import { useMyContext, getProject } from 'helpers';

type SubprojectModalProps = ModalProps & {
  configurations: Configuration[];
  projectId: string;
  subProjectType: 'pendants' | 'subProjects';
} & (
    | {
        action: 'create';
        name?: never;
        id?: never;
      }
    | {
        action: 'edit';
        name: string;
        id: string;
      }
  );

const getInitialSelectedConfigurations = (
  configurations: (Configuration & { isSelected: boolean })[]
) => {
  const initialState: { [key: string]: boolean } = {};

  for (const configuration of configurations) {
    initialState[configuration.id] = configuration.isSelected;
  }

  return initialState;
};

const SubprojectModal = ({
  action,
  isOpen,
  onClose,
  projectId,
  configurations,
  name,
  id,
  subProjectType,
}: SubprojectModalProps) => {
  const { t } = useTranslation();
  const { createProjectItem, editProjectItem, projects } = useMyContext();
  const [subprojectName, setSubprojectName] = useState(name || '');
  const allConfigurations = [
    ...configurations.map((c) => ({ ...c, isSelected: false })),
    ...(id
      ? getProject(projects, projectId, subProjectType, id)?.configurations.map(
          (c) => ({
            ...c,
            isSelected: true,
          })
        ) || []
      : []),
  ];
  const [selectedConfigurations, setSelectedConfigurations] = useState(
    getInitialSelectedConfigurations(allConfigurations)
  );

  const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedConfigurations((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));

  const handleClick = () => {
    const configs = Object.entries(selectedConfigurations)
      .filter(([, isSelected]) => isSelected)
      .map(([id]) => id);

    if (action === 'create') {
      ReactGA.event({
        category: 'CableTracker.subprojectModal',
        action: 'click',
        label: 'create',
      });
      createProjectItem('subProjects', projectId, subprojectName, configs);
    } else {
      ReactGA.event({
        category: 'CableTracker.subprojectModal',
        action: 'click',
        label: 'edit_project_item',
      });
      editProjectItem(
        subProjectType,
        projectId,
        name,
        subprojectName,
        id,
        configs
      );
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} className="subproject-modal">
      <Modal.Header onClose={onClose}>
        {action} {t('summary.subproject.name.header')}
      </Modal.Header>
      <Modal.Content>
        <label>
          <strong>{t('java.export.subProjectName')}</strong>
          <input
            type="text"
            value={subprojectName}
            placeholder={
              t('java.export.subproject.placeholder') || 'My sub-project'
            }
            onChange={(e) => {
              ReactGA.event({
                category: 'CableTracker.subprojectModal',
                action: 'click',
                label: 'rename_subproject',
              });
              setSubprojectName(e.target.value);
            }}
          />
        </label>
        <label>
          <strong>{t('project.modal.configuration.select.config')}</strong>
          {allConfigurations.map(({ id, name }) => (
            <label className="configuration-checkbox">
              <Checkbox
                name={id}
                checked={selectedConfigurations[id]}
                onChange={handleCheckedChange}
              />
              {name}
            </label>
          ))}
        </label>
      </Modal.Content>
      <Modal.Footer>
        <button onClick={onClose} className="btn-tertiary">
          {t('global.close')}
        </button>
        <button disabled={!subprojectName} onClick={handleClick}>
          {t('download.modal.ok')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubprojectModal;
