import './styles.css';
import { HiPlus, HiMinus } from 'react-icons/hi';

interface CounterProps {
  count: number;
  increment: () => void;
  decrement: () => void;
  onInputChange: (value: number) => void;
}

const Counter = ({
  count,
  increment,
  decrement,
  onInputChange,
}: CounterProps) => {
  return (
    <div className="counter">
      <button onClick={decrement} className="center-xy">
        <HiMinus />
      </button>
      <input
        type="text"
        value={count}
        onChange={(e) => onInputChange(parseInt(e.target.value))}
      />
      <button onClick={increment} className="center-xy">
        <HiPlus />
      </button>
    </div>
  );
};

export default Counter;
