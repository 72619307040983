import { FiAlertTriangle } from 'react-icons/fi';

interface NoteProps {
  iconColor?: string;
  text: string;
}

const Note = ({ text, iconColor }: NoteProps) => (
  <p className="center-y note">
    <FiAlertTriangle
      color={iconColor || '#00b3ff'}
      size={24}
      style={{ marginRight: '0.5rem' }}
    />
    {text}
  </p>
);

export default Note;
