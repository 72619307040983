import './styles.css';
import { Modal } from 'shared/components';
import { ModalProps } from 'shared/types';
import { useTranslation } from 'react-i18next';
import { useMyContext } from 'helpers';

const salesLocations = [
  {
    location: 'Noord Oost',
    manager: 'Andre Oude Kamphuis',
    email: 'andre.oude-kamphuis@wibe-group.com',
    phone: '',
  },
  {
    location: 'Noord Westt',
    manager: 'Ruben van Ginkel',
    email: 'ruben.van.ginkel@wibe-group.com',
    phone: '0683805734',
  },
  {
    location: 'Zuid West',
    manager: 'Michael Stadhouders',
    email: 'michael.stadhouders@wibe-group.com',
    phone: '0622391101',
  },
  {
    location: 'Zuid Oost',
    manager: 'Johan de Zeeuw',
    email: 'johan.dezeeuw@wibe-group.com',
    phone: '0623990623',
  },
  {
    location: 'West',
    manager: 'Simon Dupper',
    email: 'simon.dupper@wibe-group.com',
    phone: '0653400638',
  },
];

const ContactInfoModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();

  const { currentProject } = useMyContext();

  return (
    <Modal isOpen={isOpen}>
      <Modal.Header className="contact-info-header" onClose={onClose}>
        {t('contact.modal.header')}
      </Modal.Header>
      <Modal.Content className="contact-info-modal">
        <section>
          <p className="text-heavy">
            {t('modal.contact.customerService.label')}
          </p>
          <a href="tel:+31 229 847040 ">+31 229 847040 </a>
          <a href="mailto:support-NL@wibe-group.com">
            support-NL@wibe-group.com
          </a>
        </section>
        <section className="contact-sales">
          <h2>{t('modal.contact.contactSales')}</h2>

          {salesLocations.map(({ location, manager, email, phone }) => (
            <div key={`option-${location}`}>
              <p className="text-heavy">{location}</p>
              <p>{manager}</p>
              <p className="text-accent">{t('contact.modal.send.email')}</p>
              <a href={`mailto:${email}}`}>{email}</a>
              <a href={`tel::${phone}}`}>{t('contact.modal.give.us.a.call')}</a>
              <p>{phone}</p>
            </div>
          ))}

          <p>{t('contact.modal.contact.tip')} '{currentProject?.remoteId}'</p>
        </section>
      </Modal.Content>
      <Modal.Footer className="contact-info-footer">
        <button className="btn-tertiary" onClick={onClose}>
          {t('global.close')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactInfoModal;
