import './styles.css';
import OptionalInput from '../OptionalInput';
import Counter from '../Counter';
import Note from '../Note';
import Modal from '../Modal';
import { useModalState } from 'hooks';
import { useTranslation } from 'react-i18next';

import type { Bend } from 'components/MultiStepForm/types';

interface AttachmentCardProps extends Bend {
  quantity: number;
  coverQuantity: number;
  hasCouplers: boolean;
  setBendCount: (
    bendOrCover: 'bend' | 'cover',
    id: string,
    name: string,
    count: number
  ) => void;
}

const ExtendedCouplerImageMapping: Record<string, string> = {
  T01: 'https://wibe-group.com/storage/6C9F216DB142EA379EA32C4E4DA3EAB0312E10DE80AF154D30FAB345E2F9807F/1600254e5fd24b9587c66b0c81ab6a94/png/media/df8c3177088d49debecb7ab058617fc2/T-Piece%20Smooth.png',
  T02: 'https://wibe-group.com/storage/0DB0E8754C298B70DF3B54C8D539DCC45257527E86CA997E9FB3DB186A543C9F/cb46199af1af476dbac74fbcc2fc21f5/png/media/085d452828ab41af970fa3a53b15e65f/T-piece%20Hard.png',
  'Edged Corner':
    'https://wibe-group.com/storage/1192892735D36CC215F51DE1BB217885D79EF1B1156D7EE3E22510670E24E03F/8dd7c58175bb43ae992d9db8bf5b73e3/png/media/e42a30d8f26946619465015ad3fdc04d/Corner%20Small%20Smooth.png',
  'Straight Corner':
    'https://wibe-group.com/storage/745B8725FAAF7F58DBB452EB395168D89F3F9C0892F44BF3B550C07C04A98CDC/b181f9081b1d4973b06c80f1d00f8f64/png/media/4b7f743bd3d848bc95c87a04e053a275/Corner%20Small%20Hard.png',
  'Straigth corner':
    'https://wibe-group.com/storage/745B8725FAAF7F58DBB452EB395168D89F3F9C0892F44BF3B550C07C04A98CDC/b181f9081b1d4973b06c80f1d00f8f64/png/media/4b7f743bd3d848bc95c87a04e053a275/Corner%20Small%20Hard.png',
  'C1 + C2 + C3':
    'https://wibe-group.com/storage/C58843F38F0CAF0D5E87269CF9DBFC8B63429D5E73D322ED8D17068E5F30B969/89e9d4409f2e408eaa2f2a16ccc937a8/png/media/3923b0c3b7f34d87932d8db0bbd3e6ee/Corner%20Wide%20Smooth.png',
  'Right angle flat bend':
    'https://wibe-group.com/storage/745B8725FAAF7F58DBB452EB395168D89F3F9C0892F44BF3B550C07C04A98CDC/b181f9081b1d4973b06c80f1d00f8f64/png/media/4b7f743bd3d848bc95c87a04e053a275/Corner%20Small%20Hard.png',
  'Right angle flat bend1':
    'https://wibe-group.com/storage/1192892735D36CC215F51DE1BB217885D79EF1B1156D7EE3E22510670E24E03F/8dd7c58175bb43ae992d9db8bf5b73e3/png/media/e42a30d8f26946619465015ad3fdc04d/Corner%20Small%20Smooth.png',
  'T piece':
    'https://wibe-group.com/storage/6C9F216DB142EA379EA32C4E4DA3EAB0312E10DE80AF154D30FAB345E2F9807F/1600254e5fd24b9587c66b0c81ab6a94/png/media/df8c3177088d49debecb7ab058617fc2/T-Piece%20Smooth.png',
  'T piece1':
    'https://wibe-group.com/storage/0DB0E8754C298B70DF3B54C8D539DCC45257527E86CA997E9FB3DB186A543C9F/cb46199af1af476dbac74fbcc2fc21f5/png/media/085d452828ab41af970fa3a53b15e65f/T-piece%20Hard.png',
  'Wide corner':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/a23e5ba14265495ca563e6a8d2544972.png',
  'Wide corner1':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/a23e5ba14265495ca563e6a8d2544972.png',
  Reducer:
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/c4b507ab3b554fcb8c8280b475253c90.png',
  Reducer1:
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/e9818d5da4d440cdbd09134703e56fe4.png',
  Raisers:
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/143cef4b45d14c00ac6dcc15dbf73ff9.png',
};

const AttachmentCard = ({
  cr,
  type,
  name,
  quantity,
  coverQuantity,
  imageUrl,
  setBendCount,
  hasCover,
  hasCouplers,
}: AttachmentCardProps) => {
  const { t } = useTranslation();
  const [isOpen, open, close] = useModalState(false);

  const setStartLidCount = (val: boolean) => {
    setBendCount('cover', cr, name, quantity);
  }

  const resetLidCount = () => {
    setBendCount('cover', cr, name, 0);
  }

  return (
    <>
      <div className="attachment-card">
        <strong>
          {type} - {name}
        </strong>
        <div>
          <div className="attachment-image center-x">
            <img src={imageUrl} alt="" />
            {ExtendedCouplerImageMapping[name] && hasCouplers && (
              <>
                <img src={ExtendedCouplerImageMapping[name]} alt="" />
                <span onClick={open}>{t('backend.installation.guide')}</span>
              </>
            )}
          </div>
          <div className="attachment-quantity-counters">
            <Counter
              count={quantity}
              increment={() => setBendCount('bend', cr, name, quantity + 1)}
              decrement={() => setBendCount('bend', cr, name, quantity - 1)}
              onInputChange={(val) => setBendCount('bend', cr, name, val)}
            />
            {hasCover ? (
              <OptionalInput
                isInitiallyOpen={coverQuantity > 0}
                toggleText={
                  <span className="include-lid">
                    {t('selected.coupler.bend.item.include.lid')}
                  </span>
                }
                setStartLidCount={() => setStartLidCount(false)}
                resetLidCount={() => resetLidCount()}
              >
                <Counter
                  count={coverQuantity}
                  increment={() =>
                    setBendCount('cover', cr, name, coverQuantity + 1)
                  }
                  decrement={() =>
                    setBendCount('cover', cr, name, coverQuantity - 1)
                  }
                  onInputChange={(val) => setBendCount('cover', cr, name, val)}
                />
              </OptionalInput>
            ) : (
              <div style={{ width: 190 }}></div>
            )}
          </div>

          <div
            className="alert"
            style={{
              visibility: quantity < coverQuantity ? 'visible' : 'hidden',
            }}
          >
            <Note
              iconColor="#fcb900"
              text={t('validate.bend.quantities.msg')
                .replace(/#coverQuantity/g, quantity.toString())
                .replace(/#bendQuantity/g, coverQuantity.toString())}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen}>
        <Modal.Header onClose={close}>{name}</Modal.Header>
        <Modal.Content>
          {ExtendedCouplerImageMapping[name] && hasCouplers && (
            <img
              src={ExtendedCouplerImageMapping[name]}
              alt={'coupler-img'}
              width="100%"
            />
          )}
        </Modal.Content>
        <Modal.Footer style={{ flexDirection: 'row-reverse' }}>
          <button className="btn-tertiary">{t('global.close')}</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AttachmentCard;
