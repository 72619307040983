import './styles.css';
import Counter from '../Counter';

import type { Accessory } from 'components/MultiStepForm/types';

interface AccessoryCardProps extends Accessory {
  quantity: number;
  setQuantity: (accessory: string, count: number) => void;
}

const AccessoryCard = ({
  cr,
  crDescription,
  imageUrl,
  quantity,
  setQuantity,
  packaging,
}: AccessoryCardProps) => {
  return (
    <div className="accessory-card">
      <strong>{crDescription}</strong>
      <div>
        <img src={imageUrl} alt="" />
        <p className="text-secondary center-xy">{packaging}</p>
        <Counter
          count={quantity}
          increment={() => setQuantity(cr, quantity + 1)}
          decrement={() => setQuantity(cr, quantity - 1)}
          onInputChange={(val) => setQuantity(cr, val)}
        />
      </div>
    </div>
  );
};

export default AccessoryCard;
