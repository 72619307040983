import './styles.css';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import Tooltip from '../Tooltip';

interface HelpProps {
  style?: React.CSSProperties;
  children: React.ReactElement;
  withBorder?: boolean;
  position?: {
    top?: number;
    left?: number;
  };
}

const Help = ({ style, children, position }: HelpProps) => {
  return (
    <Tooltip
      content={children}
      position={{ top: 15, left: -240, ...position }}
      delay={0}
      relativeToParent
      withBorder
    >
      <div className="help">
        <AiOutlineQuestionCircle size={24} />
      </div>
    </Tooltip>
  );
};

export default Help;
