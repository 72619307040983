import './styles.css';
import { ModalProps } from 'shared/types';
import Modal from '../Modal';
import Note from '../Note';

interface SingleInputModalProps extends ModalProps {
  className?: string;
  title: string;
  inputConfig: {
    label: string;
    placeholder: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    note?: string;
  };
  actions?: {
    dismiss?: {
      label?: string;
      onClick?: () => void;
    };
    confirm: {
      label?: string;
      onClick?: () => void;
    };
  };
}

const SingleInputModal = ({
  isOpen,
  onClose,
  inputConfig,
  title,
  actions,
  className,
}: SingleInputModalProps) => {
  return (
    <Modal isOpen={isOpen} className={`single-input-modal ${className}`}>
      <Modal.Header onClose={onClose}>{title}</Modal.Header>
      <Modal.Content>
        <div>
          <label>
            <strong>
              {inputConfig.label}
              <input
                type="text"
                placeholder={inputConfig.placeholder}
                value={inputConfig.value}
                onChange={inputConfig.onChange}
              />
            </strong>
          </label>
          {inputConfig.note && <Note text={inputConfig.note} />}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <button
          onClick={actions?.dismiss?.onClick || onClose}
          className="btn-tertiary"
        >
          {actions?.dismiss?.label || 'Cancel'}
        </button>
        <button onClick={actions?.confirm.onClick}>
          {actions?.confirm.label || 'Save'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SingleInputModal;
