import './styles.css';
import { Tooltip } from 'shared/components';
import { useModalState } from 'hooks';
import UpsertConfigurationModal from '../UpsertConfigurationModal';
import { useMyContext } from 'helpers';
import SubprojectModal from '../SubprojectModal';
import ReactGA from 'react-ga4';
import {
  DeleteItem,
  DuplicateItem,
  EditItem,
  LoadConfiguration,
  PendantInfo,
} from 'assets/icons/projectItem';
import PendantInfoModal from '../PendantInfoModal';

import type { Configuration } from 'shared/types';

type ProjectItemProps = {
  projectId: string;
  name: string;
  isActive: boolean;
  id: string;
} & (
  | {
      type: 'subProjects';
      configurations: Configuration[];
      currentConfiguration: string;
      subProjectId?: never;
      parentType?: never;
      isCeiling?: never;
    }
  | {
      type: 'pendants';
      configurations: Configuration[];
      currentConfiguration: string;
      subProjectId?: never;
      parentType?: never;
      isCeiling: boolean;
    }
  | {
      type: 'configurations';
      configurations?: never;
      currentConfiguration?: never;
      subProjectId?: string;
      parentType?: 'subProjects' | 'pendants';
      isCeiling?: never;
    }
);

const ProjectItem = ({
  id,
  name,
  type,
  projectId,
  isActive,
  configurations,
  currentConfiguration,
  subProjectId,
  parentType,
  isCeiling,
}: ProjectItemProps) => {
  const [isOpen, open, close] = useModalState({
    configurations: false,
    subProjects: false,
    pendants: false,
    pendantInfo: false,
  });
  const {
    deleteProjectItem,
    duplicateProjectItem,
    loadConfig,
    currentProject,
  } = useMyContext();

  const isSubProject = type === 'subProjects' || type === 'pendants';
  
  return (
    <div
      className="project-configuration"
      style={{
        borderColor: !isSubProject ? 'transparent' : '',
        backgroundColor: isActive && !isSubProject ? 'var(--accent-color)' : '',
        color: isActive && !isSubProject ? 'white' : '',
      }}
    >
      <div
        style={{
          marginBottom: isSubProject && configurations!.length ? '1rem' : '',
        }}
      >
        <div className="project-item-name">{name}</div>
        <div className="center-y">
          {!isSubProject && !isActive && (
            <Tooltip content="Load configuration">
              <div
                className="center-y"
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.myProject',
                    action: 'click',
                    label: 'load_configuration',
                  });
                  loadConfig(projectId, id, subProjectId, parentType);
                }}
              >
                <LoadConfiguration />
              </div>
            </Tooltip>
          )}
          {type === 'pendants' && !isCeiling && (
            <PendantInfo onClick={() => open('pendantInfo')} />
          )}
          <Tooltip content={`Rename ${type}`}>
            <div
              className="center-y"
              onClick={() => {
                ReactGA.event({
                  category: 'CableTracker.myProject',
                  action: 'open_project',
                  label: 'project_type_' + type,
                });
                open(type);
              }}
            >
              <EditItem />
            </div>
          </Tooltip>
          <Tooltip content={`Copy ${type}`}>
            <div
              className="center-y"
              onClick={() => {
                ReactGA.event({
                  category: 'CableTracker.myProject',
                  action: 'click',
                  label: 'copy_project',
                });
                duplicateProjectItem(
                  type,
                  projectId,
                  id,
                  subProjectId,
                  parentType
                );
              }}
            >
              <DuplicateItem />
            </div>
          </Tooltip>
          {!isActive && (
            <Tooltip content={`Delete ${type}`}>
              <div
                className="center-y"
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.myProject',
                    action: 'click',
                    label: 'delete_project_item',
                  });
                  deleteProjectItem(
                    type,
                    projectId,
                    id || name,
                    subProjectId,
                    parentType
                  );
                }}
              >
                <DeleteItem />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {isSubProject &&
        configurations!.map(({ id: configId, name }) => (
          <ProjectItem
            type="configurations"
            id={configId}
            projectId={projectId}
            subProjectId={id}
            parentType={type}
            name={name}
            isActive={isActive && currentConfiguration === configId}
          />
        ))}
      <UpsertConfigurationModal
        action="edit"
        id={id}
        isOpen={isOpen.configurations}
        onClose={() => close(type)}
        project={projectId}
        subProjectId={subProjectId || ''}
        configName={name}
        subProjectType={parentType}
      />
      {isSubProject && (
        <SubprojectModal
          subProjectType={type}
          action="edit"
          projectId={projectId}
          isOpen={isOpen[type]}
          onClose={() => close(type)}
          configurations={currentProject?.configurations || []}
          name={name}
          id={id!}
        />
      )}

      {id && (
        <PendantInfoModal
          id={id}
          parentId={projectId}
          isOpen={isOpen.pendantInfo}
          onClose={() => close('pendantInfo')}
        />
      )}
    </div>
  );
};

export default ProjectItem;
