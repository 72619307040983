import './styles.css';
import { useState, useContext } from 'react';
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { Modal } from 'shared/components';
import { ApiHashContext } from 'contexts/apiHashContext';
import type { ModalProps } from 'shared/types';
import { SpinnerCircularFixed } from 'spinners-react';
import { API_HOST } from 'shared/constants';

interface ExportProjectModalProps extends ModalProps {
  format: 'pdf' | 'xlsx';
  payload: any;
  name: string;
  noDistributor: boolean;
}

const ExportProjectModal = ({
  name,
  format,
  isOpen,
  onClose,
  payload,
  noDistributor = false,
}: ExportProjectModalProps) => {
  const { t } = useTranslation();
  const { apiHash } = useContext(ApiHashContext);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    company: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const startDownload = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${API_HOST}/wibe/export/${format}/${apiHash}?noDistributor=${noDistributor}`,
        payload,
        {
          responseType: 'arraybuffer',
        }
      );
      const blob = new File([res.data], name, {
        type: `application/${
          format === 'pdf'
            ? 'pdf'
            : 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }`,
      });

      ReactGA.event({
        category: 'CableTracker.exportProject',
        action: 'download',
        label: 'format_' + format,
      });
      const aElement = document.createElement('a');

      aElement.setAttribute('download', name);
      const href = URL.createObjectURL(blob);

      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
    onClose();
  };

  if (isLoading)
    return (
      <Modal isOpen={isOpen}>
        <Modal.Header onClose={onClose}>
          {t('download.modal.download.started')}
        </Modal.Header>
        <Modal.Content>
          <SpinnerCircularFixed
            size={32}
            thickness={150}
            speed={125}
            color="var(--accent-color)"
            secondaryColor="#eeeeee"
            style={{ marginRight: '1rem' }}
          />
          <p>{t('download.modal.file.should.be.ready.soon')}</p>
        </Modal.Content>
        <Modal.Footer style={{ flexDirection: 'row-reverse' }}>
          <button onClick={onClose}>{t('download.modal.ok')}</button>
        </Modal.Footer>
      </Modal>
    );

  return (
    <Modal isOpen={isOpen} className="export-modal">
      <Modal.Header onClose={onClose}>{t('modal.export.header')}</Modal.Header>
      <Modal.Content style={{ flexDirection: 'column' }}>
        <label>
          <strong>{t('modal.export.person.label')}</strong>
          <input
            placeholder={t('modal.export.person.placeholder') || 'John Doe'}
            type="text"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
        </label>
        <label>
          <strong>{t('modal.export.company.label')}</strong>
          <input
            type="text"
            name="company"
            value={form.company}
            onChange={handleChange}
          />
        </label>
      </Modal.Content>
      <Modal.Footer>
        <button className="btn-tertiary" onClick={onClose}>
          {t('global.cancel')}
        </button>
        <button onClick={startDownload}>{t('global.next')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportProjectModal;
