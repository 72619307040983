const materials = [
  {
    name: 'classification.items.c1.title',
    application: 'classification.items.c1.desc',
    icon: 'https://wibe-group.com/storage/2C8C57E248E28B01834FF66086E0D18991F1AB4F79F63B2888A087DE5558D840/a5617a42c5754add8e4b258b10f17dc7/png/media/4faac602bb294487bfd7d9c2d8909d9e/CC_C1.png',
    extraInfo: [
      'classification.items.c1.details1',
      'classification.items.c1.details2',
      'classification.items.c1.details3',
      'classification.items.c1.details4',
      'classification.items.c1.details5',
    ],
  },
  {
    name: 'classification.items.c2.title',
    application: 'classification.items.c2.desc',
    icon: 'https://wibe-group.com/storage/8C2DD684CCE1989DB0BA2BE332627DD310BBC68FFEC256CCEA9A8E6F1A49B737/f3b81ea1d43445bc86abb0e8a8175e8e/png/media/2367ece4718543b49a935188a938c524/CC_C2.png',
    extraInfo: [
      'classification.items.c2.details1',
      'classification.items.c2.details2',
      'classification.items.c2.details3',
      'classification.items.c2.details4',
    ],
  },
  {
    name: 'classification.items.c3.title',
    application: 'classification.items.c3.desc',
    icon: 'https://wibe-group.com/storage/6BAB24FBFB3CF107B46150B71AC855916AC2396C190447362E1E775C8FE93AF8/348398adb05c4c388e4a8dbaac1c1ddc/png/media/b43c5cfb01d0491c964df4d432bae66a/CC_C3.png',
    extraInfo: [
      'classification.items.c3.details1',
      'classification.items.c3.details2',
      'classification.items.c3.details3',
      'classification.items.c3.details4',
    ],
  },
  {
    name: 'classification.items.c4.title',
    application: 'classification.items.c4.desc',
    icon: 'https://wibe-group.com/storage/E4602805C4077490EC1D315E2289A00261C8A19A8A916870E69D05EB09E236CB/122cf5375f5d4d0ea936f52dcb2865bf/png/media/5a452d1b1d7e4077879a87cd27927e1f/CC_C4.png',
    extraInfo: [
      'classification.items.c4.details1',
      'classification.items.c4.details2',
      'classification.items.c4.details3',
      'classification.items.c4.details4',
    ],
  },
  {
    name: 'classification.items.c5i.title',
    application: 'classification.items.c5i.desc',
    icon: 'https://wibe-group.com/storage/794742CAB0F19DA132416AA494F90C413C89A7D6659B0FF782C31810A266C333/cb1485a79145451b83714310a28222ee/png/media/8ef3e7bfd5b247d2a7a8bbbd7dd11608/CC_C5-I.png',
    extraInfo: [
      'classification.items.c5i.details1',
      'classification.items.c5i.details2',
      'classification.items.c5i.details3',
    ],
  },
  {
    name: 'classification.items.c5m.title',
    application: 'classification.items.c5m.desc',
    icon: 'https://wibe-group.com/storage/406F4383A7548DD430BFC40DB743D8DE70E16A358F3A1C11EC07567885F03FF3/76387c3f9f684331875a6d8040080602/png/media/60b93a32695745aaa34d6aa4b428e2dc/CC_C5-M.png',
    extraInfo: [
      'classification.items.c5m.details1',
      'classification.items.c5m.details2',
      'classification.items.c5m.details3',
    ],
  },
] as const;

export default materials;
