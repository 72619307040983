import { useState } from 'react';
import ReactGA from 'react-ga4';
type InitialModalState = boolean | { [key: string]: boolean };

const useModalState = <T extends InitialModalState>(initialValue: T) => {
  const [isOpen, setIsOpen] = useState(initialValue);

  type OpenOrCloseModal = T extends {
    [key: string]: boolean;
  }
    ? (id: keyof T) => void
    : () => void;

  const open = ((id) => {
    ReactGA.event({
      category: 'CableTracker.modal',
      action: 'open',
    });
    if (typeof isOpen === 'object') {
      // @ts-ignore
      setIsOpen((prev) => ({ ...prev, [id]: true }));
    } else {
      // @ts-ignore
      setIsOpen(true);
    }
  }) as OpenOrCloseModal;

  const close = ((id) => {
    ReactGA.event({
      category: 'CableTracker.modal',
      action: 'close',
    });
    if (typeof isOpen === 'object') {
      // @ts-ignore
      setIsOpen((prev) => ({ ...prev, [id]: false }));
    } else {
      // @ts-ignore
      setIsOpen(false);
    }
  }) as OpenOrCloseModal;

  return [isOpen, open, close] as const;
};

export default useModalState;
