const generateStateFromArray = <T, K extends keyof T, V>(
  arr: T[],
  key: K,
  initialValue: V
) => {
  // @ts-ignore
  const initialState: { [key in T[K]]: V } = {} as {
    // @ts-ignore
    [key in T[K]]: V;
  };

  arr.forEach((item) => {
    initialState[item[key]] = initialValue;
  });

  return initialState;
};

export default generateStateFromArray;
