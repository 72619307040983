import './styles.css';
import { IoClose } from 'react-icons/io5';

interface ModalComponentProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
interface ModalProps extends ModalComponentProps {
  isOpen: boolean;
}

const Modal = ({ children, isOpen, className = '', style }: ModalProps) =>
  isOpen ? (
    <div className="modal-overlay">
      <div className={`modal-container ${className}`} style={style}>
        {children}
      </div>
    </div>
  ) : null;

interface HeaderProps extends Omit<ModalComponentProps, 'children'> {
  onClose: (e: React.MouseEvent) => void;
  onlyCloseButton?: true;
  children?: React.ReactNode;
  withoutCloseButton?: boolean;
}

const Header = ({
  children,
  onClose,
  className = '',
  style,
  onlyCloseButton,
  withoutCloseButton,
}: HeaderProps) => {
  return (
    <h2
      className={`modal-header ${className}`}
      style={{ marginBottom: onlyCloseButton ? 0 : '', ...style }}
    >
      {children || <div></div>}
      {!withoutCloseButton && (
        <IoClose onClick={onClose} color="#00b3ff" size={36} />
      )}
    </h2>
  );
};

const Content = ({ children, className = '', style }: ModalComponentProps) => (
  <div className={`modal-content ${className}`} style={style}>
    {children}
  </div>
);

const Footer = ({ children, className = '', style }: ModalComponentProps) => (
  <div className={`modal-footer ${className}`} style={style}>
    <hr />
    {children}
  </div>
);

Modal.Header = Header;
Modal.Content = Content;
Modal.Footer = Footer;

export default Modal;
