import { useState } from 'react';

import { ModalProps } from 'shared/types';
import { SingleInputModal } from 'shared/components';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

interface EditProjectModalProps extends ModalProps {
  initialProjectName: string;
  onEdit: (name: string) => void;
}

const EditProjectModal = ({
  isOpen,
  onClose,
  initialProjectName,
  onEdit,
}: EditProjectModalProps) => {
  const { t } = useTranslation();
  const [projectName, setProjectName] = useState(initialProjectName);

  return (
    <SingleInputModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('project.modal.edit.project') || 'Edit Project'}
      inputConfig={{
        label: t('global.project.name'),
        placeholder: t('placeholder.my.project'),
        value: projectName,
        onChange: (e) => {
          ReactGA.event({
            category: 'CableTracker.editProjectModal',
            action: 'click',
            label: 'rename_project',
          });
          setProjectName(e.target.value);
        },
      }}
      actions={{
        dismiss: {
          label: t('global.close') || 'Close',
          onClick: () => {
            ReactGA.event({
              category: 'CableTracker.editProjectModal',
              action: 'click',
              label: 'close',
            });
            onClose();
            setProjectName(initialProjectName);
          },
        },
        confirm: {
          onClick: () => {
            ReactGA.event({
              category: 'CableTracker.editProjectModal',
              action: 'click',
              label: 'confirm_changes',
            });
            onEdit(projectName);
            onClose();
          },
        },
      }}
    />
  );
};

export default EditProjectModal;
