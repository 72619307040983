import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import qsLanguageDetector from './qs_language_detector';
import {
  translationEN,
  translationRU,
  translationFR,
  translationDA,
  translationDE,
  translationES,
  translationNL,
  translationNO,
  translationSV,
} from './translations/index';

const resourceFiles = {
  ru: {
    translation: translationRU,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  da: {
    translation: translationDA,
  },
  es: {
    translation: translationES,
  },
  nl: {
    translation: translationNL,
  },
  no: {
    translation: translationNO,
  },
  sv: {
    translation: translationSV,
  },
};
const languageDetector = new LanguageDetector();
// languageDetector.addDetector(qsLanguageDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resourceFiles,
    fallbackLng: 'nl',
    detection: {
      order: ['querystring', 'localStorage'],
      lookupQuerystring: 'language',
      caches: ['cookies', 'localStorage'],
    },

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
