import './styles.css';
import { useContext } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Help, Loader } from 'shared/components';
import { materials } from 'assets';
import { useMyContext } from 'helpers';
import { Material } from '@/shared/types';
import { ApiHashContext } from 'contexts/apiHashContext';
import { API_HOST } from 'shared/constants';

const MaterialForm = () => {
  const { t } = useTranslation();
  const { setConfig, goForth, currentConfiguration } = useMyContext<1>();
  const { apiHash } = useContext(ApiHashContext);
  const { isLoading, error, data } = useQuery(
    'step1Data',
    () =>
      axios
        .get<{ availableClassification: string[] }>(
          `${API_HOST}/wibe/step1/${apiHash}`,
          {
            data: {},
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => res.data.availableClassification),
    { refetchOnWindowFocus: false }
  );

  if (isLoading) return <Loader />;
  if (error)
    return (
      <p className="center-x api-error">
        {t('fetching.material.classifications.error.msg')}
      </p>
    );

  return (
    <div className="material-form">
      <h2>{t('main.step1.select.material')}</h2>
      <div className="materials-container">
        {data?.length ? (
          <div>
            {materials.map((material) => {
              const isAvailable = data.find((am) =>
                t(material.name).startsWith(am)
              );

              return (
                <div
                  key={`option-${material.name}-${material.application}`}
                  onClick={() => {
                    isAvailable &&
                      setConfig(
                        {
                          material: t(material.name).split(' ')[0] as Material,
                        },
                        true
                      );

                    ReactGA.event({
                      category: 'CableTracker.materials',
                      action: 'click',
                      label: t(material.name).split(' ')[0],
                    });
                  }}
                  className={isAvailable ? '' : 'material--unavailable'}
                  style={{
                    border:
                      currentConfiguration?.data.material ===
                      t(material.name).split(' ')[0]
                        ? '2px solid var(--accent-color)'
                        : '2px solid #eeeeee',
                  }}
                >
                  <div className="material-name">{t(material.name)}</div>
                  <p>{t(material.application)}</p>
                  <img src={material.icon} alt={'material-img'} width="150" />
                  <Help>
                    <ul>
                      {material.extraInfo.map((info) => (
                        <li key={`option-${info}`}>{t(info)}</li>
                      ))}
                    </ul>
                  </Help>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="center-x">{t('no.materials.found.error.msg')}</p>
        )}
      </div>
      <div className="form-footer">
        <button
          onClick={goForth}
          disabled={!currentConfiguration?.data.material}
        >
          {t('global.next')}
        </button>
      </div>
    </div>
  );
};

export default MaterialForm;
