import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { ProjectsProvider } from 'contexts/projectsContext';
import { ApiHashProvider } from 'contexts/apiHashContext';
import { QueryClient, QueryClientProvider } from 'react-query';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ApiHashProvider>
        <ProjectsProvider>
          <App />
        </ProjectsProvider>
      </ApiHashProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
