import { useState } from 'react';
import { SingleInputModal } from 'shared/components';
import { ModalProps } from 'shared/types';
import { useMyContext } from 'helpers';
import { useTranslation } from 'react-i18next';

const ExistingProjectModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const [projectId, setProjectId] = useState('');
  const { loadProject } = useMyContext();

  return (
    <>
      <SingleInputModal
        className="existing-project-modal"
        isOpen={isOpen}
        onClose={onClose}
        title={t('project.modal.apply.project.code')}
        inputConfig={{
          label: t('project.modal.enter.received.project.code'),
          placeholder: t('placeholder.my.project'),
          value: projectId,
          onChange: (e) => setProjectId(e.target.value),
          note:
            t('project.modal.changes.made.to.this.project.msg2') ||
            'Note: Changes made to this project are only visible on this device',
        }}
        actions={{
          dismiss: {
            label: t('global.close') || 'Close',
          },
          confirm: {
            label: t('project.modal.load') || 'Load',
            onClick: () => {
              loadProject(projectId);
              onClose();
            },
          },
        }}
      />
    </>
  );
};

export default ExistingProjectModal;
