import './styles.css';
import { Modal } from 'shared/components';
import { ModalProps } from 'shared/types';
import { useTranslation } from 'react-i18next';

interface InstallationModalProps extends ModalProps {
  name: string;
  width: string;
  treatment: string;
  type: string;
  img: string;
}

const InstallationGuideModal = ({
  isOpen,
  onClose,
  name,
  width,
  treatment,
  type,
  img,
}: InstallationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} className="installation-guide-modal">
      <Modal.Header onClose={onClose}>
        {t('shape.modal.gutter.side.perforation')}
      </Modal.Header>
      <Modal.Content>
        <div>
          <label>
            <span className="text-secondary">{t('shape.modal.name')}</span>
            <p>{name}</p>
          </label>
          <label>
            <span className="text-secondary">{t('shape.modal.widths')}</span>
            <p>{width}</p>
          </label>
          <label>
            <span className="text-secondary">
              {t('global.surface.treatment')}
            </span>
            <p>{treatment}</p>
          </label>
          <label>
            <span className="text-secondary">{t('global.type')}</span>
            <p>{type}</p>
          </label>
        </div>
        <div className="center-xy">
          <img src={img} width={360} alt={"modal-img"}/>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <button className="btn-tertiary" onClick={onClose}>
          {t('global.close')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default InstallationGuideModal;
