import './styles.css';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { useState } from 'react';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  borderBottom?: boolean;
}

const Accordion = ({ title, children, borderBottom }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <div
      className="accordion"
      style={{ borderBottom: borderBottom ? '1px solid #e0e0e0' : '' }}
      onClick={toggle}
    >
      <div
        className="center-y"
        style={{ borderBottom: isOpen ? '1px solid #e0e0e0' : '' }}
      >
        {isOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
        <strong>{title}</strong>
      </div>
      <div className="accordion-content" onClick={(e) => e.stopPropagation()}>
        {isOpen ? children : null}
      </div>
    </div>
  );
};

export default Accordion;
