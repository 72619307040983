import { createContext, useState, useEffect } from 'react';

export const ApiHashContext = createContext({} as { apiHash: string });

interface ApiHashContextProps {
  children: React.ReactNode;
}

const getDistributorQP = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('distributor') || false;
};

const getDistributorHashByQP = () => {
  const distributorQP = getDistributorQP();
  if (!distributorQP) {
    return false;
  }

  switch (distributorQP) {
    case 'internal_tests': return 'f4586ad10ac6b76146c1bf0e0755a8539d313caaa73d777c2eb27b8f41b17b23';
    case 'oosterberg_nl': return 'ca6724eab9d0b613a57a97eafba85dc6a210b39fb90c866d571e20808cda9fdc';
    case 'nextuser_com': return 'cb77ea97307947e5ad756670f51ea07c8eb33f0f7352cc6df44365a4990242df';
    case 'rexelnl': return '6472d5c1677640d8b00fd0e5c1357c2b5157f81193012b063134bc027ddc65fe';
    case 'technischeunie_nl': return '86f56a51090a64e72882fc6f54eeb6326f77e56700c2584aacd181607d96932c';
    case 'itek': return 'ca6724eab9d0b613a57a97eafba85dc6a210b39fb90c866d571e20808cda9fdc';
  }

  return false;
};

export const ApiHashProvider = ({ children }: ApiHashContextProps) => {
  const [apiHash, setApiHash] = useState('');

  const getApiHash = () => {
    const distributorHashQP = getDistributorHashByQP();
    const defaultHash =
      window.location.href.indexOf('wibe') !== -1
        ? '86f56a51090a64e72882fc6f54eeb6326f77e56700c2584aacd181607d96932c'
        : 'f4586ad10ac6b76146c1bf0e0755a8539d313caaa73d777c2eb27b8f41b17b23';

    return distributorHashQP !== false ? distributorHashQP : defaultHash;
  };

  useEffect(() => {
    setApiHash(getApiHash());
  }, []);

  return apiHash ? (
    <ApiHashContext.Provider value={{ apiHash }}>
      {children}
    </ApiHashContext.Provider>
  ) : null;
};

export default ApiHashProvider;
