import axios from 'axios';

import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import type { ModalProps } from 'shared/types';

interface PDFModalProps extends ModalProps {
  url: string;
}

const PDFModal = ({ isOpen, onClose, url }: PDFModalProps) => {
  const { t } = useTranslation();
  const getPdf = async () => {
    const res = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([res.data], { type: 'application/pdf' });

    return URL.createObjectURL(blob);
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Header onClose={onClose}></Modal.Header>
      <Modal.Content>
        <iframe
          title="Get PDF"
          ref={async (ref) => {
            if (ref) {
              ref.src = await getPdf();
            }
          }}
        ></iframe>
      </Modal.Content>
      <Modal.Footer>
        <button onClick={onClose} className="btn-tertiary">
          {t('global.close')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PDFModal;
