import './styles.css';
import { useTranslation } from 'react-i18next';
import {
  AttachmentForm,
  MaterialForm,
  ShapeForm,
  SizeForm,
  Overview,
} from './components';
import { useMyContext } from 'helpers';

// const steps = ['Material', 'Shape', 'Size', 'Attachment', 'Overview'];

const MultiStepForm = () => {
  const { t } = useTranslation();
  const steps = [
    t('main.content.material'),
    t('main.content.shape'),
    t('main.content.size'),
    t('main.content.attachment'),
    t('main.content.overview'),
  ];
  const { step: currentStep } = useMyContext();

  return (
    <>
      <div className="multistep-form-nav">
        {steps.map((step, index) => {
          const active = currentStep >= index + 1;

          return (
            <div key={`option-${step}`}>
              <div className={`center-xy step${active ? '--active' : ''}`}>
                {index + 1}
              </div>
              <p>{step}</p>
              <span className={`progress${active ? '--active' : ''}`} />
            </div>
          );
        })}
      </div>

      <main>
        {currentStep === 1 && <MaterialForm />}
        {currentStep === 2 && <ShapeForm />}
        {currentStep === 3 && <SizeForm />}
        {currentStep === 4 && <AttachmentForm />}
        {currentStep === 5 && <Overview />}
      </main>
    </>
  );
};

export default MultiStepForm;
