import './styles.css';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Checkbox, Modal } from 'shared/components';
import { useMyContext, generateStateFromArray } from 'helpers';
import ReactGA from 'react-ga4';
import type { ModalProps } from 'shared/types';

interface ExistingPendantsModalProps extends ModalProps {
  showAddPendantModal: () => void;
}

const ExistingPendantsModal = ({
  isOpen,
  onClose,
 showAddPendantModal,
}: ExistingPendantsModalProps) => {
  const { t } = useTranslation();
  const { currentProject, addConfigToPendant, setConfig } = useMyContext();
  const [selectedPendants, setSelectedPendants] = useState(
    generateStateFromArray(currentProject?.pendants || [], 'id', false)
  );

  const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedPendants((prev) => {
      const prevCopy = { ...prev };

      for (let key in prevCopy) {
        if (prevCopy[key] && key !== e.target.name) {
          prevCopy[key] = false;
        }

        if (key === e.target.name) {
          prevCopy[key] = e.target.checked;
        }
      }

      return prevCopy;
    });

  const isDisabled = () => !Object.values(selectedPendants).includes(true);

  return (
    <Modal isOpen={isOpen} className="existing-pendants-modal">
      <Modal.Header onClose={onClose}>{t('pendant.modal.header')}</Modal.Header>
      <Modal.Content>
        <label>
          <strong>{t('pendant.modal.select.project.to.assign')}</strong>
          {currentProject?.pendants.filter(pendant => !pendant.isCeiling).map(({ name, id }) => (
            <label className="configuration-checkbox" key={id}>
              <Checkbox
                name={id}
                checked={selectedPendants[id]}
                onChange={handleCheckedChange}
              />
              {name}
            </label>
          ))}
        </label>
      </Modal.Content>
      <Modal.Footer>
        <button onClick={onClose} className="btn-tertiary">
          {t('global.close')}
        </button>
        <button onClick={showAddPendantModal}>{t('pendant.modal.add')}</button>
        <button
          disabled={isDisabled()}
          onClick={() => {
            const pendant = Object.keys(selectedPendants).find(
              (key) => selectedPendants[key]
            );
            if (pendant) {
              setConfig({ pendant }, false);
                addConfigToPendant(pendant);
                onClose();
            }
            ReactGA.event({
              category: 'CableTracker.pendantModal',
              action: 'select_pendant',
              label: pendant,
            });
          }}
        >
          {t('pendant.modal.choose')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExistingPendantsModal;
