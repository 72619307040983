import type { Project, Steps } from 'shared/types';

const getProject = <T extends Steps = 0>(
  projects: Project<T>[],
  currentProjectId: string | null,
  subProjectType?: 'subProjects' | 'pendants',
  currentSubProjectId?: string | null
) => {
  if (!projects.length) return null;

  if (currentSubProjectId && subProjectType) {
    const currentProject = projects.find(
      (project) => project.id === currentProjectId
    );

    return currentProject
      ? currentProject[subProjectType].find(
          (s) => s.id === currentSubProjectId
        ) || null
      : null;
  }

  return projects.find((project) => project.id === currentProjectId) || null;
};

export default getProject;
