import './styles.css';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { OptionalInput, ProductThumbnail } from 'shared/components';
import { SinglePendant, DoublePendant } from 'assets/icons/pendants';
import { useMyContext } from 'helpers';
import { Modal } from 'shared/components';
import ExistingPendantsModal from '../ExistingPendantsModal';
import ReactGA from 'react-ga4';
import { Help } from 'shared/components';

import type { Pendant, Suspension } from 'components/MultiStepForm/types';

interface IPendantSteps {
  pendants: Pendant[];
  supportDistances: string[];
  onClose: () => void;
  suspensions: Suspension[];
}

type SuspensionSteps = 1 | 2 | 3 | 4 | 5 | 6;

const PendantSteps = ({
  pendants,
  onClose,
  supportDistances,
  suspensions
}: IPendantSteps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<SuspensionSteps>(2);
  const { currentConfiguration, setConfig, createProjectItem, currentProject } =
    useMyContext<3>();
  const {
    mountType,
    pendantShape,
    pendantHookCount,
    pendantDutyType,
    pendant,
    pendantSupportDistance,
    pendantProjectName,
    suspension,
    suspensionDistance,
    dutyType: selectedDutyType,
  } = currentConfiguration.data;
  const [name, setName] = useState(pendantProjectName || '');
  const [selectedPendantCr, setSelectedPendantCr] = useState(pendant || '');
  const [isExistingPendantsModalOpen, setIsOpen] = useState(
    !!currentProject.pendants.filter(pendant => !pendant.isCeiling).length
  );
  const {
    OnePendant,
    TwoPendants,
    ThreePendants,
  } = require(`assets/icons/pendants/${pendantShape || 'single'}`);

  const pendantTitles = {
    2: t('pendant.modal.header'),
    3: t('pendant.modal.type.header'),
    4: t('pendant.modal.details.header'),
    5: t('global.choose.suspension.type'),
    6: t('global.choose.bracket')
  };

  const arrayStaticSort = ['C-Beugel Licht', 'C-Beugel Zwaar', 'C-Beugel Extra Zwaar', 'Gootbeugel - Binnenliggend', 'Gootbeugel - Buitenliggend', 'Wandconsole 50', 'Wandconsole 50i'];

  const selectedPendantVariations = pendants
    .filter((p) => pendantDutyType === p.dutyType)
    .filter((v, i, a) => a.findIndex((v2) => v2.cr === v.cr) === i);

  const selectedPendant =
    selectedPendantVariations.find((p) => p.cr === selectedPendantCr) ||
    selectedPendantVariations[0];    

  const goBack = () => {
    if (step === 5) {
    }

    ReactGA.event({
      category: 'CableTracker.suspensionsModal',
      action: 'back',
      label: 'step_' + (step - 1),
    });
    setStep((step) => (step - 1) as SuspensionSteps);
  };

  const goForth = () => {
    ReactGA.event({
      category: 'CableTracker.suspensionsModal',
      action: 'next',
      label: 'step_' + (step + 1),
    });
    setStep((step) => (step + 1) as SuspensionSteps);
  };

  const isDisabled = () => {
    if (step === 2) {
      return !name || !pendantShape || !pendantHookCount;
    }
    if (step === 3) {
      return !pendantDutyType;
    }
    if (step === 5) {
      return !selectedBracket;
    }
  };

  const selectedBracketVariations = suspensions
    .filter((s) => s.mountType === mountType && s.dutyType === selectedDutyType)
    .sort((a, b) => parseInt(a.width || '') - parseInt(b.width || ''));

  const selectedBracket =
    selectedBracketVariations.find((b) => b.cr === suspension) ||
    selectedBracketVariations[0];

    const calcMinNumOfBrackets = () => {
      const length = currentConfiguration?.data?.length ?? 0;
      const defaultSupportDistance = supportDistances ? supportDistances[0] : "1";
      const parsedSupportDistance = parseFloat(defaultSupportDistance.replace(",", "."));
      const suspensionDistance = currentConfiguration?.data?.suspensionDistance ?? (isNaN(parsedSupportDistance) ? 1 : parsedSupportDistance);
      return Math.ceil(length / suspensionDistance) + 1
    };

    const calcMinNumOfPendants = () => {
      const length = currentConfiguration?.data?.length ?? 0;
      const defaultSupportDistance = supportDistances ? supportDistances[0] : "1";
      const parsedSupportDistance = parseFloat(defaultSupportDistance.replace(",", "."));
      const pendantSupportDistanceString = currentConfiguration?.data?.pendantSupportDistance;
      const parsedPendantSupportDistance = pendantSupportDistanceString ? parseFloat(pendantSupportDistanceString.replace(",", ".")) : parsedSupportDistance;
      const suspensionDistance = isNaN(parsedPendantSupportDistance) ? parsedSupportDistance : parsedPendantSupportDistance;

      return Math.ceil(length / suspensionDistance) + 1;
    };

    const replaceComma = (str: string) => {
      return str.replace(',', '.');
    }
    return isExistingPendantsModalOpen ? (
    <ExistingPendantsModal
      isOpen
      onClose={onClose}
      showAddPendantModal={() => setIsOpen(false)}
    />
  ) : (
    <>
      <Modal.Header onClose={onClose}>
        {pendantTitles[step as keyof typeof pendantTitles]}
      </Modal.Header>
      <Modal.Content style={{ flexDirection: 'column', flexWrap: 'nowrap' }}>
        {step === 2 && (
          <>
            <label>
              <strong style={{ display: 'flex' }}>
                <div style={{ marginRight: '0.5rem' }}>
                  {t('pendant.modal.pendant.name')}
                </div>
                <Help
                  position={{
                    left: 15,
                  }}
                  withBorder
                >
                  <p>{t('pendant.steps.new.config.trace.msg')}</p>
                </Help>
              </strong>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
            <strong>
              {t('pendant.modal.single.or.double.pendant.question')}
            </strong>
            <div className="pendant-configurations">
              <div
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.pendantStep2',
                    action: 'pendant_shape',
                    label: 'single',
                  });
                  setConfig({ pendantShape: 'single' }, false);
                }}
              >
                <p>{t('pendant.modal.single')}</p>
                <ProductThumbnail
                  img={<SinglePendant />}
                  selected={pendantShape === 'single'}
                />
              </div>
              <div
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.pendantStep2',
                    action: 'pendant_shape',
                    label: 'double',
                  });
                  setConfig({ pendantShape: 'double' }, false);
                }}
              >
                <p>{t('pendant.modal.double')}</p>
                <ProductThumbnail
                  img={<DoublePendant />}
                  selected={pendantShape === 'double'}
                />
              </div>
            </div>
            <strong>{t('pendant.modal.pendant.qty.question')}</strong>
            <div className="pendant-configurations">
              <div
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.pendantStep2',
                    action: 'pendant_hook_count',
                    label: '1',
                  });
                  setConfig({ pendantHookCount: 1 }, false);
                }}
              >
                <p>{t('pendant.modal.pendant.qty.single')}</p>
                <ProductThumbnail
                  img={<OnePendant />}
                  selected={pendantHookCount === 1}
                />
              </div>
              <div
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.pendantStep2',
                    action: 'pendant_hook_count',
                    label: '2',
                  });
                  setConfig({ pendantHookCount: 2 }, false);
                }}
              >
                <p>{t('pendant.modal.pendant.qty.double')}</p>
                <ProductThumbnail
                  img={<TwoPendants />}
                  selected={pendantHookCount === 2}
                />
              </div>
              <div
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.pendantStep2',
                    action: 'pendant_hook_count',
                    label: '3',
                  });
                  setConfig({ pendantHookCount: 3 }, false);
                }}
              >
                <p>{t('pendant.modal.pendant.qty.triple')}</p>
                <ProductThumbnail
                  img={<ThreePendants />}
                  selected={pendantHookCount === 3}
                />
              </div>
            </div>
          </>
        )}
        {step === 3 && pendantHookCount && (
          <>
            {pendants
              .filter((p) =>
                pendantShape === 'double'
                  ? p.supportsDoubleSided
                  : !p.supportsDoubleSided
              )
              .filter(
                (p) =>
                  parseInt(p.maxSingleSided) >= pendantHookCount ||
                  parseInt(p.maxDoubleSided) >= pendantHookCount
              )
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.dutyType === value.dutyType)
              )
              .map(({ dutyType, imageUrl, cr }) => (
                <div
                  key={`option-${dutyType}-${cr}`}
                  className="additional-option"
                  style={{
                    borderColor:
                      pendantDutyType === dutyType ? 'var(--accent-color)' : '',
                  }}
                  onClick={() => {
                    ReactGA.event({
                      category: 'CableTracker.pendantStep3',
                      action: 'pendant_duty_type',
                      label: dutyType,
                    });
                    setConfig({ pendantDutyType: dutyType }, false);
                  }}
                >
                  <img src={imageUrl} alt="" />
                  <div>
                    <strong>{dutyType}</strong>
                    <p></p>
                  </div>
                </div>
              ))}
          </>
        )}
        {step === 4 && (
          <>
            <div className="pendant-details">
              <div className="image-container">
                <img src={selectedPendant?.imageUrl} alt="" />
              </div>
              <div>
                <h4>{selectedPendant?.crDescription}</h4>
                <div className="sizes-container">
                  <p>{t('global.measure.length.mm')}</p>
                  {selectedPendantVariations.map((p) => (
                    <span
                      key={`option-${p.cr}-${p.length}`}
                      className={`size-box${p.cr === selectedPendant.cr ? '--selected' : ''
                        }`}
                      onClick={(e) => {
                        ReactGA.event({
                          category: 'CableTracker.pendantStep4',
                          action: 'selected_pendant_cr',
                          label: p.cr,
                        });
                        setSelectedPendantCr(p.cr);
                      }}
                    >
                      {p.length}
                    </span>
                  ))}
                </div>
                <div className="sizes-container">
                  <p>{t('suspension.modal.suspension.distance.m')}</p>
                  {supportDistances?.map((d) => (
                    <span
                      key={`option-${d}`}
                      className={`size-box${(
                        pendantSupportDistance
                          ? replaceComma(d) === pendantSupportDistance
                          : d === supportDistances[0]
                      )
                        ? '--selected'
                        : ''
                        }`}
                      onClick={(e) => {
                        ReactGA.event({
                          category: 'CableTracker.pendantStep4',
                          action: 'selected_pendant_support_distance',
                          label: d,
                        });
                        setConfig({ pendantSupportDistance: replaceComma(d) }, false);
                      }}
                    >
                      {d}
                    </span>
                  ))}
                </div>
                <p className="text-secondary">
                  {t('pendant.modal.min.num.of.brackets.text')} {' '}
                  {calcMinNumOfPendants()}
                </p>
              </div>
            </div>
          </>
        )}
        {step === 5 && (
          <>
            {suspensions
              .filter((s) => s.mountType === mountType)
              .filter((s) => {
                return (
                  s.mountType !== 'Pendant suspension' ||
                  s.compatiblePendantCrs === null ||
                  s.compatiblePendantCrs?.includes(
                    currentConfiguration.data?.pendant!
                  )
                );
              })
              .filter(
                (value, index, self) =>
                  index ===
                  self.findIndex((t) => t.dutyType === value.dutyType)
              )
              .sort((a, b) => arrayStaticSort.indexOf(a.dutyType) - arrayStaticSort.indexOf(b.dutyType))
              .map(({ imageUrl, safeWorkingLoad, dutyType, cr }) => (
                <div
                  key={`option-${cr}-${dutyType}`}
                  className="additional-option"
                  style={{
                    borderColor:
                      dutyType === selectedDutyType
                        ? 'var(--accent-color)'
                        : '',
                  }}
                  onClick={() => {
                    ReactGA.event({
                      category: 'CableTracker.step2',
                      action: 'duty_type',
                      label: dutyType,
                    });
                    setConfig({ dutyType: dutyType }, false);
                  }}
                >
                  <img src={imageUrl} alt={dutyType} />
                  <div>
                    <strong>{dutyType}</strong>
                    <p>
                      {t('suspension.modal.max.safe.workload')}{' '}
                      {safeWorkingLoad}
                    </p>
                  </div>
                </div>
              ))}
          </>
        )}
        {step === 6 && (
          <>
            <div className="bracket-img">
              <img
                src={selectedBracket?.imageUrl}
                alt={selectedBracket?.crDescription}
              /> 
            </div>
            <div className="bracket-info">
              <h4>{selectedBracket?.crDescription}</h4>
              <p
                className="text-secondary"
                style={{ height: 'fit-content', marginBottom: 0 }}
              >
                {t('suspension.modal.current.suspension.bracket.width')}{' '}
                {selectedBracket?.width}
                mm
              </p>
              {selectedBracketVariations.length > 1 ? (
                <OptionalInput
                  toggleText={t(
                    'suspension.modal.change.suspension.bracket.width'
                  )}
                >
                  <label className="bracket-widths">
                    {selectedBracketVariations.map((b) => (
                      <span
                        key={`option-${b.cr}-${b.width}`}
                        className={`size-box${b.cr === selectedBracket.cr ? '--selected' : ''
                          }`}
                        onClick={(e) => {
                          ReactGA.event({
                            category: 'CableTracker.step3',
                            action: 'suspension',
                            label: b.cr,
                          });
                          setConfig({ suspension: b.cr }, false);
                        }}
                      >
                        {b.width}mm
                      </span>
                    ))}
                  </label>
                </OptionalInput>
              ) : null}
              <label className="suspension-distances">
                <strong>
                  {t('suspension.modal.suspension.distance.m')}
                </strong>
                {supportDistances?.map((d) => {
                  const parsedDistance = parseFloat(replaceComma(d));
                  const isSelected = suspensionDistance
                    ? suspensionDistance === parsedDistance
                    : parsedDistance === parseFloat(replaceComma(supportDistances[0]));
                  return (
                  <span
                    key={`option-${d}`}
                    className={`size-box${isSelected ? '--selected' : ''}`}
                    onClick={() => {
                      ReactGA.event({
                        category: 'CableTracker.step3',
                        action: 'suspension_distance',
                        label: d,
                      });
                      setConfig(
                        { suspensionDistance: parsedDistance },
                        false
                      );
                    }}
                  >
                    {d}
                  </span>
                );
              })}
              </label>
              <p className="text-secondary">
                {t('suspension.modal.min.num.of.brackets.text')}{' '}
                {calcMinNumOfBrackets()}
              </p>
            </div>
          </>
        )}
      </Modal.Content>
      <Modal.Footer>
        <button onClick={onClose} className="btn-tertiary">
          {t('global.cancel')}
        </button>
        <span>
          {step > 1 && (
            <button className="btn-secondary" onClick={goBack}>
              {t('global.previous')}
            </button>
          )}
          {step < 6 && (
            <button
              onClick={() => {
                goForth();
                if (step === 2) {
                  setConfig({ pendantProjectName: name }, false);
                }
                else if (step === 4) {
                  setConfig(
                    {
                      pendant: selectedPendant.cr,
                      pendantSupportDistance:
                        pendantSupportDistance ||
                        selectedPendant.supportDistances[0],
                    },
                    false
                  );
                  createProjectItem(
                    'pendants',
                    currentProject.id,
                    pendantProjectName!,
                    [currentConfiguration.name]
                  );
                }

              }}
              disabled={isDisabled()}
            >
              {t('global.next')}
            </button>
          )}
          {step === 6 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setConfig(
                  {
                    suspension: selectedBracket.cr,
                    suspensionObj: selectedBracket,
                    suspensionDistance: parseFloat(
                      `${suspensionDistance ||
                      (selectedBracket.supportDistances
                        ? supportDistances[0]
                        : 'null')
                      }`
                    ),
                  },
                  false
                );

                ReactGA.event({
                  category: 'CableTracker.step3.finish',
                  action: 'suspension',
                  label: selectedBracket.cr,
                });
                ReactGA.event({
                  category: 'CableTracker.step3.finish',
                  action: 'suspension_object',
                  label: JSON.stringify(selectedBracket),
                });
                ReactGA.event({
                  category: 'CableTracker.step3.finish',
                  action: 'suspension_distance',
                  label: `${suspensionDistance ||
                    (selectedBracket.supportDistances
                      ? supportDistances[0]
                      : 'null')
                    }`,
                });
                onClose();
              }}
            >
              {t('global.finished')}
            </button>
          )}
        </span>
      </Modal.Footer>
    </>
  );
};

export default PendantSteps;
