import { SpinnerCircularFixed } from 'spinners-react';

const Loader = () => {
  return (
    <div className="center-xy" style={{ height: '100%' }}>
      <SpinnerCircularFixed
        size={32}
        thickness={150}
        speed={125}
        color="var(--accent-color)"
        secondaryColor="#eeeeee"
        style={{ marginRight: '1rem' }}
      />
    </div>
  );
};

export default Loader;
