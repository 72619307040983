import { useContext } from 'react';
import { ProjectsContext } from 'contexts/projectsContext';
import type { ProjectsContextValue, Steps } from 'shared/types';

const useMyContext = <Step extends Steps = 0>() => {
  const context = useContext<ProjectsContextValue<Step>>(
    ProjectsContext as unknown as React.Context<ProjectsContextValue<Step>>
  );

  if (!context) {
    throw new Error('useMyContext must be used under MyContextProvider');
  }

  return context;
};

export default useMyContext;
