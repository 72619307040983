import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { OptionalInput } from 'shared/components';
import { useMyContext } from 'helpers';
import { Modal } from 'shared/components';
import ReactGA from 'react-ga4';

import type { Pendant, Suspension } from 'components/MultiStepForm/types';
import { nanoid } from 'nanoid';

interface ICeilingSteps {
    pendants: Pendant[];
    supportDistances: string[];
    onClose: () => void;
    suspensions: Suspension[];
}

type SuspensionSteps = 1 | 2 | 3 | 4 | 5;

const CeilingSteps = ({
    pendants,
    onClose,
    supportDistances,
    suspensions
}: ICeilingSteps ) => {
    const { t } = useTranslation();
      const [step, setStep] = useState<SuspensionSteps>(2);
      const { currentConfiguration, setConfig, createProjectItem, currentProject } =
        useMyContext<3>();
      const {
        mountType,
        pendantDutyType,
        pendant,
        suspension,
        suspensionDistance,
        pendantProjectName,
        dutyType: selectedDutyType,
      } = currentConfiguration.data;
      const [selectedPendantCr, setSelectedPendantCr] = useState(pendant || '');
      const [selectedCeilingAttachmentCr, setSelectedCeilingAttachmentCr] = useState('');
    
      const pendantTitles = {
        2: t('global.choose.suspension.type'),
        3: t('pendant.modal.type.header'),
        4: t('pendant.modal.type.header'),
        5: t('pendant.modal.details.header'),
      };
    
      const goBack = () => {
        const numPendants = compatiblePendants
          .filter((value, index, self) => 
            index === self.findIndex((t) => t.dutyType === value.dutyType)).length;

        let prev = step - 1;

        if (prev === 4 && numPendants <= 1) {
          prev = 3;
        }

        ReactGA.event({
          category: 'CableTracker.suspensionsModal',
          action: 'back',
          label: 'step_' + prev,
        });
        setStep((step) => prev as SuspensionSteps);
      };
    
      const goForth = () => {
        const numPendants = compatiblePendants
          .filter((value, index, self) => 
            index === self.findIndex((t) => t.dutyType === value.dutyType)).length;
        
        let next = step === 5 ? 3 : step + 1;

        if (next === 4 && numPendants <= 1) {
          next = 5;
        }

        ReactGA.event({
          category: 'CableTracker.suspensionsModal',
          action: 'next',
          label: 'step_' + next,
        });
        setStep((step) => next as SuspensionSteps);
      };
    
      const isDisabled = () => {
        if (step === 2) {
          return !selectedBracket
        }
        if (step === 4) {
          return !pendantDutyType;
        }

        return false;
      };
    
      const selectedBracketVariations = suspensions
        .filter((s) => s.mountType ===  mountType && s.dutyType === selectedDutyType)
        .sort((a, b) => parseInt(a.width || '') - parseInt(b.width || ''));
    
      const selectedBracket =
        selectedBracketVariations.find((b) => b.cr === suspension) ||
        selectedBracketVariations[0];

      const calcMinNumOfBrackets = () => {
        const length = currentConfiguration?.data?.length ?? 0;
        const defaultSupportDistance = supportDistances ? supportDistances[0] : "1";
        const parsedSupportDistance = parseFloat(defaultSupportDistance.replace(",", "."));
        const suspensionDistance = currentConfiguration?.data?.suspensionDistance ?? (isNaN(parsedSupportDistance) ? 1 : parsedSupportDistance);
        
        return Math.ceil(length / suspensionDistance) + 1
      };

      const compatiblePendants = selectedBracket ? Array.from(
        pendants.reduce((acc, pendant) => {
          if ((selectedBracket.compatiblePendantCrs?.includes(pendant.cr) ?? false) && !acc.has(pendant.cr)) {
            acc.set(pendant.cr, pendant);
          }
          return acc;
        }, new Map()).values()
      ) : [];

      const selectedPendantVariations = pendants
        .filter((p) => pendantDutyType === p.dutyType)
        .filter((v, i, a) => a.findIndex((v2) => v2.cr === v.cr) === i);

      const selectedPendant = 
        selectedPendantVariations.find((p) => p.cr === selectedPendantCr) || 
        selectedPendantVariations[0] ||
        compatiblePendants[0];

      const shouldSetDefaultCeilingAttachment = () => {
        return selectedPendant !== null && selectedPendant !== undefined && selectedCeilingAttachmentCr === '' && selectedPendant.ceilingAttachments !== null;
      };

      const selectedCeilingAttachment =  shouldSetDefaultCeilingAttachment() ?
                    selectedPendant.ceilingAttachments[0] :
                    selectedPendant !== null && selectedCeilingAttachmentCr !== '' ? selectedPendant.ceilingAttachments?.find((c) => c.cr === selectedCeilingAttachmentCr) : null;

      return (
        <>
          <Modal.Header onClose={onClose}>
            {pendantTitles[step as keyof typeof pendantTitles]}
          </Modal.Header>
          <Modal.Content>
            {step === 2 && (
              <>
              {suspensions
                .filter((s) => s.mountType === mountType)
                .filter((s) => {
                  return (
                    s.mountType !== 'Pendant suspension' ||
                    s.compatiblePendantCrs === null ||
                    s.compatiblePendantCrs?.includes(
                      currentConfiguration.data.pendant!
                    )
                  );
                })
                .filter(
                  (value, index, self) =>
                    index ===
                    self.findIndex((t) => t.dutyType === value.dutyType)
                  )
                  .map(({ imageUrl, safeWorkingLoad, dutyType, cr, compatiblePendantCrs}) => (
                    <div
                      key={`option-${cr}-${dutyType}`}
                      className="additional-option"
                      style={{
                        borderColor:
                          dutyType === selectedDutyType
                            ? 'var(--accent-color)'
                            : '',
                      }}
                      onClick={() => {
                        ReactGA.event({
                                category: 'CableTracker.step2',
                                action: 'duty_type',
                                label: dutyType,
                            });
                            setSelectedPendantCr('');
                            setConfig({ 
                              dutyType: dutyType,
                              pendantDutyType: compatiblePendantCrs !== null ? pendants.filter((p) => p.cr === compatiblePendantCrs[0])[0].dutyType : '',
                            }, false);
                        }}
                    >
                        <img src={imageUrl} alt={dutyType} />
                        <div>
                            <strong>{dutyType}</strong>
                            <p>
                                {t('suspension.modal.max.safe.workload')}{' '}
                                {safeWorkingLoad}
                            </p>
                        </div>
                    </div>
                ))}
              </>
            )}
            {step === 3 && selectedBracket && (
              <>
              <div className="bracket-img">
                  <img
                      src={selectedBracket.imageUrl}
                      alt={selectedBracket?.crDescription}
                  />
              </div>
              <div className="bracket-info">
                  <h4>{selectedBracket?.crDescription}</h4>
                  <p
                      className="text-secondary"
                      style={{ height: 'fit-content', marginBottom: 0 }}
                  >
                      {t('suspension.modal.current.suspension.bracket.width')}{' '}
                      {selectedBracket?.width}
                      mm
                  </p>
                  {selectedBracketVariations.length > 1 ? (
                      <OptionalInput
                          toggleText={t(
                              'suspension.modal.change.suspension.bracket.width'
                          )}
                      >
                          <label className="bracket-widths">
                              {selectedBracketVariations.map((b) => (
                                  <span
                                      key={`option-${b.cr}-${b.width}`}
                                      className={`size-box${b.cr === selectedBracket.cr ? '--selected' : ''
                                          }`}
                                      onClick={(e) => {
                                          ReactGA.event({
                                              category: 'CableTracker.step3',
                                              action: 'suspension',
                                              label: b.cr,
                                          });
                                          setConfig({ suspension: b.cr }, false);
                                      }}
                                  >
                                      {b.width}mm
                                  </span>
                              ))}
                          </label>
                      </OptionalInput>
                  ) : null}
                  <label className="suspension-distances">
                      <strong>
                          {t('suspension.modal.suspension.distance.m')}
                      </strong>
                      {supportDistances?.map((d) => (
                          <span
                              key={`option-${d}`}
                              className={`size-box${(
                                  suspensionDistance
                                      ? suspensionDistance === parseFloat(d.replace(',', '.'))
                                      : (selectedBracket.supportDistances
                                          ? supportDistances[0]
                                          : '') === d
                              )
                                  ? '--selected'
                                  : ''
                                  }`}
                              onClick={() => {
                                  ReactGA.event({
                                      category: 'CableTracker.step3',
                                      action: 'suspension_distance',
                                      label: d,
                                  });
                                  setConfig(
                                      { suspensionDistance: parseFloat(d.replace(',', '.')) },
                                      false
                                  );
                              }}
                          >
                              {d}
                          </span>
                      ))}
                  </label>
                  <p className="text-secondary">
                      {t('suspension.modal.min.num.of.brackets.text')}{' '}
                      {calcMinNumOfBrackets()}
                  </p>
                  {selectedPendant && (
                    <div>
                      <h4>{selectedPendant.crDescription}</h4>
                      <p className="text-secondary">{t('global.measure.length.mm')} {selectedPendant.length}</p>
                    </div>
                  )}
                  {selectedCeilingAttachment && (
                    <div>
                      <h4>{t('suspension.modal.selectedceiling')}</h4>
                      <p className="text-secondary">{selectedCeilingAttachment.crDescription}</p>
                    </div>
                  )}                  
              </div>
          </>
            )}
            {step === 4 && (
              <>
                {compatiblePendants
                  .filter(
                    (value, index, self) =>
                      index === self.findIndex((t) => t.dutyType === value.dutyType)
                  )
                  .map(({ dutyType, imageUrl, cr }) => (
                    <div
                      key={`option-${dutyType}-${cr}`}
                      className="additional-option"
                      style={{
                        borderColor:
                          pendantDutyType === dutyType ? 'var(--accent-color)' : '',
                      }}
                      onClick={() => {
                        ReactGA.event({
                          category: 'CableTracker.pendantStep3',
                          action: 'pendant_duty_type',
                          label: dutyType,
                        });
                        setConfig({ pendantDutyType: dutyType }, false);
                      }}
                    >
                      <img src={imageUrl} alt="" />
                      <div>
                        <strong>{dutyType}</strong>
                        <p></p>
                      </div>
                    </div>
                ))}
              </>
            )}
            {step === 5 && (
              <>
               <div className="pendant-details">
                  <div className="image-container">
                    <img src={selectedPendant?.imageUrl} alt="" />
                  </div>
                  <div>
                    <h4>{selectedPendant?.crDescription}</h4>
                    <div className="sizes-container">
                      <p>{t('global.measure.length.mm')}</p>
                      {selectedPendantVariations.map((p) => (
                        <span
                          key={`option-${p.cr}-${p.length}`}
                          className={`size-box${p.cr === selectedPendant.cr ? '--selected' : ''
                            }`}
                          onClick={(e) => {
                            ReactGA.event({
                              category: 'CableTracker.pendantStep4',
                              action: 'selected_pendant_cr',
                              label: p.cr,
                            });
                            setSelectedPendantCr(p.cr);
                          }}
                        >
                          {p.length}
                        </span>
                      ))}
                    </div>
                    {selectedPendant.ceilingAttachments?.length > 0 && (
                    <div className="sizes-container">
                      <p>{t('pendant.modal.details.ceilingattachment')}:</p>
                    {selectedPendant.ceilingAttachments?.map((p) => (
                      <div
                      key={`option-${selectedPendant.cr}-${p.cr}`}
                      className="additional-option"
                      style={{
                        borderColor:
                          p.cr === selectedCeilingAttachmentCr
                            ? 'var(--accent-color)'
                            : '',
                      }}
                      onClick={() => {
                            setSelectedCeilingAttachmentCr(p.cr);
                            setConfig({
                              celingAttachment: p.cr
                            }, false);
                        }}
                    >
                        <div>
                            <strong>{p.crDescription}</strong>
                            <p>
                                {p.cr}
                            </p>
                        </div>
                    </div>
                    ))}
                    </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </Modal.Content>
          <Modal.Footer>
              <button onClick={onClose} className="btn-tertiary">
                {t('global.cancel')}
              </button>
              <span>
                {step > 1 && (
                  <button className="btn-secondary" onClick={goBack}>
                    {t('global.previous')}
                  </button>
                )}
                {step !== 3 && (
                  <button disabled={isDisabled()} onClick={goForth}>
                    {t('global.next')}
                  </button>
                )}
                {step === 3 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const idToUse = pendantProjectName ?? `Ceiling-${currentConfiguration.name}`;
                      setConfig(
                        {
                          pendantProjectName: idToUse,
                        },
                        false
                      );

                      setConfig(
                        {
                          suspension: selectedBracket.cr,
                          suspensionObj: selectedBracket,
                          suspensionDistance: suspensionDistance || parseFloat(supportDistances[0].replace(",", ".")),
                          pendant: selectedPendant.cr,
                          pendantSupportDistance: suspensionDistance?.toString().replace(".", ",") || supportDistances[0],
                          pendantDutyType: selectedPendant.dutyType,
                          celingAttachment: selectedCeilingAttachment?.cr,
                        },
                        false
                      );

                      createProjectItem(
                        'pendants',
                        currentProject.id,
                        idToUse,
                        [currentConfiguration.name]
                      );

                      ReactGA.event({
                        category: 'CableTracker.step3.finish',
                        action: 'suspension',
                        label: selectedBracket.cr,
                      });
                      ReactGA.event({
                        category: 'CableTracker.step3.finish',
                        action: 'suspension_object',
                        label: JSON.stringify(selectedBracket),
                      });
                      ReactGA.event({
                        category: 'CableTracker.step3.finish',
                        action: 'suspension_distance',
                        label: `${
                          suspensionDistance ||
                          (selectedBracket.supportDistances
                            ? supportDistances[0]
                            : 'null')
                        }`,
                      });
                      onClose();
                    }}
                  >
                    {t('global.finished')}
                  </button>
                )}
              </span>
            </Modal.Footer>
        </>)
}

export default CeilingSteps;
