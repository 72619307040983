import { Suspension } from "@/components/MultiStepForm/types";
import { useMyContext } from "helpers";
import { useState } from "react";
//import ExistingPendantsModal from '../ExistingPendantsModal';
import Modal from "shared/components/Modal";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga4';
import OptionalInput from "../..";

interface IWallSteps {
    supportDistances: string[];
    suspensions: Suspension[];
    onClose: () => void;
}

type SuspensionSteps = 1 | 2 | 3;

const WallSteps = ({
    supportDistances,
    suspensions,
    onClose,
}: IWallSteps) => {
    const { t } = useTranslation();
    const [step, setStep] = useState<SuspensionSteps>(2);

    const { currentConfiguration, setConfig } =
        useMyContext<3>();
    const {
        mountType,
        suspension,
        suspensionDistance,
        dutyType: selectedDutyType,
    } = currentConfiguration.data;

    // const [isExistingPendantsModalOpen, setIsOpen] = useState(
    //     !!currentProject.pendants.length
    // );

    const pendantTitles = {
        2: t('pendant.modal.header'),
        3: t('pendant.modal.type.header'),
        4: t('pendant.modal.details.header'),
    };

    const selectedBracketVariations = suspensions
        .filter((s) => s.mountType === 'Wall suspension' && s.dutyType === selectedDutyType)
        .sort((a, b) => parseInt(a.width || '') - parseInt(b.width || ''));

    const selectedBracket =
        selectedBracketVariations.find((b) => b.cr === suspension) ||
        selectedBracketVariations[0];

    const calcMinNumOfBrackets = () => {
        const length = currentConfiguration?.data?.length ?? 0;
        const defaultSupportDistance = supportDistances ? supportDistances[0] : "1";
        const parsedSupportDistance = parseFloat(defaultSupportDistance.replace(",", "."));
        const suspensionDistance = currentConfiguration?.data?.suspensionDistance ?? (isNaN(parsedSupportDistance) ? 1 : parsedSupportDistance);
        
        return Math.ceil(length / suspensionDistance) + 1
    };

    const goBack = () => {
        ReactGA.event({
            category: 'CableTracker.suspensionsModal',
            action: 'back',
            label: 'step_' + (step - 1),
        });
        setStep((step) => (step - 1) as SuspensionSteps);
    };

    const goForth = () => {
        ReactGA.event({
            category: 'CableTracker.suspensionsModal',
            action: 'next',
            label: 'step_' + (step + 1),
        });
        setStep((step) => (step + 1) as SuspensionSteps);
    };

    const isDisabled = () => {
        if (step === 1) {
            return !mountType;
        }
        if (step === 2) {
            return !selectedBracket;
        }
    };

    return (
        <>
            <Modal.Header onClose={onClose}>
                {pendantTitles[step as keyof typeof pendantTitles]}
            </Modal.Header>
            <Modal.Content className={'suspension-step-' + step}>
                {step === 2 && (
                    <>
                        {suspensions
                            .filter((s) => s.mountType === mountType)
                            .filter((s) => {
                                return (
                                    s.mountType !== 'Pendant suspension' ||
                                    s.compatiblePendantCrs === null ||
                                    s.compatiblePendantCrs?.includes(
                                        currentConfiguration.data.pendant!
                                    )
                                );
                            })
                            .filter(
                                (value, index, self) =>
                                    index ===
                                    self.findIndex((t) => t.dutyType === value.dutyType)
                            )
                            .map(({ imageUrl, safeWorkingLoad, dutyType, cr }) => (
                                <div
                                    key={`option-${cr}-${dutyType}`}
                                    className="additional-option"
                                    style={{
                                        borderColor:
                                            dutyType === selectedDutyType
                                                ? 'var(--accent-color)'
                                                : '',
                                    }}
                                    onClick={() => {
                                        ReactGA.event({
                                            category: 'CableTracker.step2',
                                            action: 'duty_type',
                                            label: dutyType,
                                        });
                                        setConfig({ dutyType: dutyType }, false);
                                    }}
                                >
                                    <img src={imageUrl} alt={dutyType} />
                                    <div>
                                        <strong>{dutyType}</strong>
                                        <p>
                                            {t('suspension.modal.max.safe.workload')}{' '}
                                            {safeWorkingLoad}
                                        </p>
                                    </div>
                                </div>
                            ))}
                    </>
                )}
                {step === 3 && (
                    <>
                        <div className="bracket-img">
                            <img
                                src={selectedBracket.imageUrl}
                                alt={selectedBracket?.crDescription}
                            />
                        </div>
                        <div className="bracket-info">
                            <h4>{selectedBracket?.crDescription}</h4>
                            <p
                                className="text-secondary"
                                style={{ height: 'fit-content', marginBottom: 0 }}
                            >
                                {t('suspension.modal.current.suspension.bracket.width')}{' '}
                                {selectedBracket?.width}
                                mm
                            </p>
                            {selectedBracketVariations.length > 1 ? (
                                <OptionalInput
                                    toggleText={t(
                                        'suspension.modal.change.suspension.bracket.width'
                                    )}
                                >
                                    <label className="bracket-widths">
                                        {selectedBracketVariations.map((b) => (
                                            <span
                                                key={`option-${b.cr}-${b.width}`}
                                                className={`size-box${b.cr === selectedBracket.cr ? '--selected' : ''
                                                    }`}
                                                onClick={(e) => {
                                                    ReactGA.event({
                                                        category: 'CableTracker.step3',
                                                        action: 'suspension',
                                                        label: b.cr,
                                                    });
                                                    setConfig({ suspension: b.cr }, false);
                                                }}
                                            >
                                                {b.width}mm
                                            </span>
                                        ))}
                                    </label>
                                </OptionalInput>
                            ) : null}
                            <label className="suspension-distances">
                                <strong>
                                    {t('suspension.modal.suspension.distance.m')}
                                </strong>
                                {supportDistances?.map((d) => (
                                    <span
                                        key={`option-${d}`}
                                        className={`size-box${(
                                            suspensionDistance
                                                ? suspensionDistance === parseFloat(d.replace(',', '.'))
                                                : (selectedBracket.supportDistances
                                                    ? supportDistances[0]
                                                    : '') === d
                                        )
                                            ? '--selected'
                                            : ''
                                            }`}
                                        onClick={() => {
                                            ReactGA.event({
                                                category: 'CableTracker.step3',
                                                action: 'suspension_distance',
                                                label: d,
                                            });
                                            setConfig(
                                                { suspensionDistance: parseFloat(d.replace(',', '.')) },
                                                false
                                            );
                                        }}
                                    >
                                        {d}
                                    </span>
                                ))}
                            </label>
                            <p className="text-secondary">
                                {t('suspension.modal.min.num.of.brackets.text')}{' '}
                                {calcMinNumOfBrackets()}
                            </p>
                        </div>
                    </>
                )}
            </Modal.Content>
            <Modal.Footer>
                <button onClick={onClose} className="btn-tertiary">
                    {t('global.cancel')}
                </button>
                <span>
                    {step > 1 && (
                        <button className="btn-secondary" onClick={goBack}>
                            {t('global.previous')}
                        </button>
                    )}
                    {step < 3 && (
                        <button
                            disabled={isDisabled()}
                            onClick={goForth}>
                            {t('global.next')}
                        </button>
                    )}
                    {step === 3 && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                setConfig(
                                    {
                                        suspension: selectedBracket.cr,
                                        suspensionObj: selectedBracket,
                                        suspensionDistance: parseFloat(
                                            `${suspensionDistance ||
                                            (selectedBracket.supportDistances
                                                ? supportDistances[0]
                                                : 'null')
                                            }`
                                        ),
                                    },
                                    false
                                );

                                ReactGA.event({
                                    category: 'CableTracker.step3.finish',
                                    action: 'suspension',
                                    label: selectedBracket.cr,
                                });
                                ReactGA.event({
                                    category: 'CableTracker.step3.finish',
                                    action: 'suspension_object',
                                    label: JSON.stringify(selectedBracket),
                                });
                                ReactGA.event({
                                    category: 'CableTracker.step3.finish',
                                    action: 'suspension_distance',
                                    label: `${suspensionDistance ||
                                        (selectedBracket.supportDistances
                                            ? supportDistances[0]
                                            : 'null')
                                        }`,
                                });
                                onClose();
                            }}
                        >
                            {t('global.finished')}
                        </button>
                    )}
                </span>
            </Modal.Footer>
        </>
    )
}

export default WallSteps;