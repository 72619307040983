import './styles.css';
import { Modal, Note } from 'shared/components';
import { ModalProps } from 'shared/types';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
interface CodeProps extends ModalProps {
  code: string;
}

const ProjectCodeModal = ({ code, isOpen, onClose }: CodeProps) => {
  const { t } = useTranslation();
  const copyProjectCode = () => {
    ReactGA.event({
      category: 'CableTracker.projectCodeModal',
      action: 'click',
      label: 'copy_project_code',
    });
    navigator.clipboard.writeText(code);
    onClose();
  };

  let noteMessage = t('project.modal.changes.made.to.this.project.msg2');

  return (
    <Modal isOpen={isOpen} className="project-code-modal">
      <Modal.Header onClose={onClose}>
        {t('project.code.modal.project.code')}
      </Modal.Header>
      <Modal.Content>
        <p>{t('project.code.modal.share.project.with.others.msg')}</p>
        <h3 className="center-x">{code}</h3>
        <Note text={noteMessage} />
      </Modal.Content>
      <Modal.Footer>
        <button className="btn-tertiary" onClick={onClose}>
          {t('global.close')}
        </button>
        <button onClick={copyProjectCode}>
          {t('project.code.modal.copy')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectCodeModal;
