import './styles.css';
import { BsFillCheckCircleFill } from 'react-icons/bs';

interface ProductThumbnailProps {
  onClick?: () => void;
  img: string | React.ReactNode;
  text?: string;
  selected: boolean;
}

const ProductThumbnail = ({
  onClick,
  img,
  text,
  selected,
}: ProductThumbnailProps) => {
  return (
    <div className={`product-thumbnail${selected ? '--selected' : ' '}`}>
      {typeof img === 'string' ? (
        <img src={img} alt={'thumbnail'} className="thumbnail-img" />
      ) : (
        <span className="thumbnail-img">{img}</span>
      )}
      {text && (
        <div
          className="center-xy"
          onClick={(e) => {
            e.stopPropagation();
            onClick && onClick();
          }}
        >
          {text}
        </div>
      )}
      <BsFillCheckCircleFill size={24} fill="#909291" />
    </div>
  );
};

export default ProductThumbnail;
