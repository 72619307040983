import './styles.css';
import { useState, cloneElement, useRef } from 'react';
import { TooltipPosition } from 'shared/types';

interface TooltipProps {
  children: React.ReactElement;
  content: React.ReactElement | string;
  position?: TooltipPosition;
  delay?: number;
  relativeToParent?: boolean;
  withBorder?: boolean;
}

const Tooltip = ({
  children,
  content,
  delay,
  relativeToParent,
  position: fixedPosition,
  withBorder,
}: TooltipProps) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  });

  const onMouseMove = (e: MouseEvent) => {
    if (delay === 0) {
      setIsOpen(true);
      fixedPosition
        ? setPosition(fixedPosition)
        : setPosition(
            fixedPosition || {
              top: e.clientY + 20,
              left: e.clientX + 13,
            }
          );

      return;
    }

    isOpen && setIsOpen(false);
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setIsOpen(true);
      setPosition(
        fixedPosition || {
          top: e.clientY + 20,
          left: e.clientX + 13,
        }
      );
    }, delay || 500);
  };

  const onMouseLeave = () => {
    setIsOpen(false);
    setPosition({
      top: 0,
      left: 0,
    });
    clearTimeout(timeoutRef.current);
  };

  return cloneElement(children, {
    onMouseMove,
    onMouseLeave,
    style: {
      ...children.props.style,
      ...(relativeToParent ? { position: 'relative' } : {}),
    },
    children: (
      <>
        {children.props.children}
        {isOpen && (
          <div
            className="tooltip"
            style={{
              ...position,
              border: withBorder ? '1px solid gray' : '',
            }}
          >
            {content}
          </div>
        )}
      </>
    ),
  });
};

export default Tooltip;
